import React, {useEffect, useState} from 'react'
import api from "../../../api/axiosConfig";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";

const CreateWorkOrderQuote = (props) => {
  const selectedWorkOrderId = props.selectedWorkOrderId;

  const initialWorkOrderState = {
    id: "",
    clientId: "",
    clientName: "",
    clientProjectId: "",
    clientReference: "",
    createdDate: "",
    projectAddress: "",
    projectCode: "",
    projectName: "",
    status: "",
    ourRef: "",
    quoteCreateDate: "",
    workOrderQuoteId: null,
  };

  const [workOrder, setWorkOrder] = useState(initialWorkOrderState);
  const [viewQuotePdf, setViewQuotePdf] = useState(true);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [workOrderItemList, setWorkOrderItemList] = useState([]);

  const getWorkOrder = async () => {
    try {
      // const response = await api.get(`/api/work-orders/${selectedWorkOrderId}`);
      const response = await api.get(`/api/work-order-quote/${selectedWorkOrderId}`);
      console.log(response.data);
      setWorkOrder(response.data);
      console.log("status: " + workOrder.status);
      console.log("workOrderQuoteId: " + workOrder.workOrderQuoteId);
    } catch (err) {
      console.log(err);
    }
  };

  const validateInputData = (data) => {
    if (data.ourRef === undefined || data.ourRef === null || data.ourRef.trim() === "") {
      toast.error("Quote reference number cannot be empty");
      return false;
    }
    if (data.quoteCreateDate === undefined || data.quoteCreateDate === null || data.quoteCreateDate.trim() === "") {
      toast.error("Quote created date cannot be empty");
      return false;
    }

    for (var i = 0; i < data.workOrderItemQuoteRequestsList.length; i++) {
      if (data.workOrderItemQuoteRequestsList[i].hourClaimRate === undefined ||
          data.workOrderItemQuoteRequestsList[i].hourClaimRate === null ||
          data.workOrderItemQuoteRequestsList[i].hourClaimRate.trim() === "") {
        toast.error("[Line No. " + (i + 1) + "] Claim fee rate invalid");
        return false;
      }

      if(!isDayClaimRateValid(data.workOrderItemQuoteRequestsList[i].hourClaimRate)){
        toast.error("[Line No. " + (i + 1) + "] Claim fee rate invalid. Currency value should be with 2 decimal places");

        return false;
      }
    }

    return true;
  };

  var isDayClaimRateValid = function(val) {
    var objRegExp = new RegExp("^\\d+\\.\\d{2}$", "g");
    return objRegExp.test(val);
  };

  var isOtRateValid = function(val) {
    var objRegExp = new RegExp("^\\d+\\.\\d{0,2}$", "g");
    return objRegExp.test(val);
  };


  const getWorkOrderItems = async () => {
    try {
      const response = await api.get(`/api/work-order-item-quote?clientWorkOrderId=${selectedWorkOrderId}`);
      console.log(response.data);
      setWorkOrderItemList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const saveWorkOrderQuote = async (data) => {
    try {
      const response = await api.post("/api/work-order-quote", data);
      toast.success("Work Order Quote created successfully");
      console.log(response.data);
      getWorkOrder();
      getWorkOrderItems();
    } catch (err) {
      console.log(err);
    }
    setShowUpdateButton(false)
    setViewQuotePdf(true);
  };

  const updateWorkOrderQuote = async (data) => {
    try {
      const response = await api.put(`/api/work-order-quote/${data.workOrderQuoteId}`, data);
      toast.success("Work Order Quote updated successfully");
      console.log(response.data);
      getWorkOrder();
      getWorkOrderItems();
    } catch (err) {
      console.log(err);
    }
    setShowUpdateButton(false)
    setViewQuotePdf(true);
  };

  const handleInputChange = (index, evnt) => {
    evnt.preventDefault();
    const {name, value} = evnt.target;
    const rowsInput = [...workOrderItemList];
    rowsInput[index][name] = value;
    setWorkOrderItemList(rowsInput);
  };

  const handleQuoteCreateDate = (date) => {
    setWorkOrder({...workOrder, ["quoteCreateDate"]: date});
    console.log("Start date: " + date);
  };

  const handleInputOtRateChange = event => {
    const {name, value} = event.target;
    setWorkOrder({...workOrder, [name]: value});
  };

  function prepareAndCreateQuote() {
    var data = {
      clientWorkOrderId: "",
      clientId: "",
      clientProjectId: "",
      currencyCode: "SGD",
      ourRef: "",
      quoteCreateDate: "",
      workOrderItemQuoteRequestsList: [],
    };

    Object.assign(data, workOrder);
    data.clientWorkOrderId = workOrder.id;
    data.workOrderItemQuoteRequestsList = workOrderItemList.map(copyWorkOrderItemQuote, workOrder.clientWorkOrderId);

    return data;
  }

  function copyWorkOrderItemQuote(workOrderItem) {
    return {
      clientWorkOrderId: workOrderItem.clientWorkOrderId,
      clientWorkOrderItemId: workOrderItem.id,
      hourClaimRate: workOrderItem.hourClaimRate,
    };
  }

  const createQuote = (e) => {
    e.preventDefault();
    let data = prepareAndCreateQuote();
    if (validateInputData(data)) {
      saveWorkOrderQuote(data);
    }
  };

  const updateQuote = (e) => {
    e.preventDefault();
    let data = prepareAndCreateQuote();
    if (validateInputData(data)) {
      updateWorkOrderQuote(data);
    }
  };

  const handleEditQuote = () => {
    setShowUpdateButton(true);
    setViewQuotePdf(false);
  };

  const handleDiscard = () => {
    setShowUpdateButton(false);
    setViewQuotePdf(true);
    getWorkOrder();
    getWorkOrderItems();
  };

  const handleViewQuotePdf = (e) => {
    e.preventDefault();
    props.onViewQuotePdf();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    props.onHide();
  };

  useEffect(() => {
    getWorkOrder();
    getWorkOrderItems();
    console.log("Selected id: " + selectedWorkOrderId);
  }, []);

  function stringDateFormat(dateStr) {
    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-SG', options);
  }

  function stringTimeFormat(dateStr) {
    var date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-SG', {timeStyle: 'short', timeZone: 'Asia/Singapore', hour12: true}).format(date)
  }

  return (
      <div className="container">
        <div className="row">
          <div className="offset-lg-0 col-lg-12">
            <div className="card border-0">
              <div className="card-header border-0 center-content">
                <h4>View Work Order</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Client Reference</h6>
                  </div>
                  <div className="col-sm-4">
                    <h6>{workOrder.clientName}</h6>
                  </div>
                  <div className="col-sm-2">
                    <h6>Our Ref</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="ourRef" value={workOrder.ourRef} onChange={handleInputOtRateChange}
                           disabled={viewQuotePdf && workOrder.workOrderQuoteId}
                           name="ourRef"/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Client Reference</h6>
                  </div>
                  <div className="col-sm-4">
                    <h6>{workOrder.clientReference}</h6>
                  </div>
                  <div className="col-sm-2">
                    <h6>Date</h6>
                  </div>
                  <div className="col-sm-3">
                    <DatePicker
                        className="form-control"
                        selected={Date.parse(workOrder.quoteCreateDate)}
                        onChange={(update) => {
                          handleQuoteCreateDate(update)
                        }}
                        dateFormat="d MMMM yyyy"
                        disabled={viewQuotePdf && workOrder.workOrderQuoteId}
                        style={{width: '100%'}}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Project</h6>
                  </div>
                  <div className="col-sm-4">
                    <h6>{workOrder.projectName}</h6>
                  </div>
                  <div className="col-sm-2">
                  </div>
                  <div className="col-sm-2">
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    &nbsp;
                  </div>
                  <div className="col-sm-4">
                    &nbsp;
                  </div>
                  <div className="col-sm-2">
                  </div>
                  <div className="col-sm-2">
                  </div>
                </div>

                <div>
                  <table className="table table-bordered">
                    <thead className="bg-black text-white">
                    <tr>
                      <td>Assignee Type</td>
                      <td>Purpose</td>
                      <td>Period</td>
                      <td>Time slot</td>
                      <td>Claim Fee Rate(S$) - (Per Hour)</td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      workOrderItemList &&
                      workOrderItemList.map((workOrderItem, index) => (
                          <tr key={workOrderItem.id}>
                            <td>{workOrderItem.assigneeType}</td>
                            <td>{workOrderItem.projectSection}</td>
                            <td>
                              {
                                stringDateFormat(workOrderItem.requestStartDate) + " - " +
                                (workOrderItem.requestEndDate !== null ? stringDateFormat(workOrderItem.requestEndDate) : "") +
                                " (" + workOrderItem.periodTypeDescription + ")"
                              }
                            </td>
                            <td>{stringTimeFormat(workOrderItem.startTime) + " - " + stringTimeFormat(workOrderItem.endTime)}</td>
                            <td>
                              <input className="form-control" id="hourClaimRate" value={workOrderItem.hourClaimRate}
                                     disabled={viewQuotePdf && workOrder.workOrderQuoteId}
                                     onChange={(evnt) => (handleInputChange(index, evnt))} name="hourClaimRate"/>
                            </td>
                          </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer border-0 center-content">
                {(!workOrder.workOrderQuoteId) &&
                <>
                  <button type="button" onClick={createQuote}
                          className="btn btn-success btn-sm">Create Quote
                  </button>
                </>
                }
                {(workOrder.workOrderQuoteId) && (workOrder.status === 'PENDING_SUBMIT_TO_CLIENT') &&
                <>
                  {
                    showUpdateButton &&
                    <button type="button" onClick={updateQuote}
                            className="btn btn-success btn-sm">Update Quote
                    </button>
                  }
                  {
                    !showUpdateButton &&
                    < button type="button" onClick={handleEditQuote}
                             className="btn btn-success btn-sm">Edit Quote
                    </button>
                  }
                  {
                    <>
                      &nbsp;
                      < button type="button" onClick={handleDiscard}
                               disabled={viewQuotePdf}
                               className="btn btn-success btn-sm">Discard
                      </button>
                    </>
                  }
                </>
                }
                &nbsp;
                <button type="button" onClick={handleViewQuotePdf}
                        disabled={!viewQuotePdf || !workOrder.workOrderQuoteId}
                        className="btn btn-success btn-sm">View Quote PDF
                </button>
                &nbsp;
                <button type="button" onClick={handleCancel}
                        className="btn btn-success btn-sm">Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default CreateWorkOrderQuote

