import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";

import api from "../../api/axiosConfig";
import Button from "react-bootstrap/Button";
import EditAssigneeDialog from "./dialog/assignee/EditAssigneeDialog";
import AddAssigneeDialog from "./dialog/assignee/AddAssigneeDialog";
import Spinner from "react-bootstrap/Spinner";
import DeleteAssigneeDialog from "./dialog/assignee/DeleteAssigneeDialog";

const Assignee = () => {
  const navigate = useNavigate();

  const [selectedAssigneeId, setSelectedAssigneeId] = React.useState(null);
  const [selectedAssigneeUserId, setSelectedAssigneeUserId] = React.useState(null);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [addModalShow, setAddModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const [assigneeTypeList, setAssigneeTypeList] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [isAssigneesLoading, setIsAssigneesLoading] = React.useState(false);

  const getAssigneeTyps = async () => {
    try {
      const response = await api.get("/api/assignee-types");
      console.log(response.data);
      setAssigneeTypeList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const getAssignees = async () => {
    setIsAssigneesLoading(true);
    try {
      const response = await api.get("/api/assignees");
      console.log(response.data);
      setAssigneeList(response.data.content);
    } catch (err) {
      console.log(err);
    } finally {
      setIsAssigneesLoading(false);
    }
  };

  const deleteAssignee = async () => {
    try {
      const response = await api.delete(`/api/assignees/${selectedAssigneeId}`);
      console.log(response.data);
      getAssignees();
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewAssignee = (assigneeId, assigneeUserId) => {
    navigate(`/assignee/view/${assigneeUserId}`);
  };

  const handleUpdateAssignee = (assigneeId, assigneeUserId) => {
    setSelectedAssigneeId(assigneeId);
    setSelectedAssigneeUserId(assigneeUserId);
    setEditModalShow(true);
  };

  const handleHideAddModal = () => {
    getAssignees();
    setAddModalShow(false);
  };

  const handleHideEditModal = () => {
    getAssignees();
    setEditModalShow(false);
  };

  const handleHideDeleteModal = () => {
    getAssignees();
    setDeleteModalShow(false);
  };

  const handleDelete = (assigneeId, assigneeUserId) => {
    setSelectedAssigneeId(assigneeId);
    setSelectedAssigneeUserId(assigneeUserId);
    setDeleteModalShow(true);
  };

  useEffect(() => {
    getAssigneeTyps();
    getAssignees()
  }, []);

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>Assignees</h2>
          </div>
          <div className="card-body border-0">
            <div>
              {/*<Link className="btn btn-success" to="/assignee/create">Add Assignee</Link>*/}
              <>
                <Button variant="btn btn-success" onClick={() => setAddModalShow(true)}>
                  Add Assignee
                </Button>

                < AddAssigneeDialog
                    show={addModalShow}
                    onHide={() => handleHideAddModal()}
                    assigneeTypeList={assigneeTypeList}
                />

                <EditAssigneeDialog
                    show={editModalShow}
                    onHide={() => handleHideEditModal()}
                    selectedAssigneeId={selectedAssigneeId}
                    assigneeTypeList={assigneeTypeList}
                />

                <DeleteAssigneeDialog
                    show={deleteModalShow}
                    onHide={() => handleHideDeleteModal()}
                    deleteAssignee={() => deleteAssignee()}
                />
              </>
            </div>
          </div>
        </div>

        <table className="table table-bordered">
          <thead className="bg-black text-white">
          <tr>
            {/*<td>ID</td>*/}
            <td>NAME</td>
            <td>TYPE</td>
            <td>MOBILE</td>
            <td>EMAIL</td>
            <td>Cert Reg No</td>
            <td>Cert Expiry</td>
            <td>Cert File Name</td>
            <td>ACTION</td>
          </tr>
          </thead>
          <tbody>
          {
            isAssigneesLoading ? (
                <tr>
                  <td rowSpan="1" colSpan="8">
                    <div className="text-center py-5">
                      <Spinner animation="border"/>
                    </div>
                  </td>
                </tr>
            ) : assigneeList && assigneeList.length > 0 ?
                (assigneeList.map((assignee) => (
                    <tr key={assignee.id}>
                      {/*<td>{assignee.id}</td>*/}
                      <td>{assignee.name}</td>
                      <td>{assignee.assigneeType}</td>
                      <td>{assignee.mobileNo}</td>
                      <td>{assignee.emailAddress}</td>
                      <td>{assignee.certRegistrationNo}</td>
                      <td>{assignee.certExpiryDate}</td>
                      <td width="15%">{assignee.originalFileName}</td>
                      <td>
                        <button type="button" onClick={() => {
                          handleViewAssignee(assignee.id, assignee.userId)
                        }}
                                className="btn btn-dark btn-sm">View
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {
                          handleUpdateAssignee(assignee.id, assignee.userId)
                        }}
                                className="btn btn-success btn-sm">Edit
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {
                          handleDelete(assignee.id, assignee.userId)
                        }}
                                className="btn btn-danger btn-sm">Delete
                        </button>
                      </td>
                    </tr>
                ))) : (
                    <tr>
                      <td colSpan="8">
                        <h5 className="text-danger text-center">No registered assignees found</h5>
                      </td>
                    </tr>
                )
          }
          </tbody>
        </table>
      </div>
  )
}

export default Assignee
