import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DeleteClientDialog = (props) => {

  const handleDeleteClient = () => {
    props.deleteClient();
    props.onHide();
  };

  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to delete the client entry?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteClient}>Delete</Button>
          <Button variant="success" onClick={props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
  )
};

export default DeleteClientDialog
