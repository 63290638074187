import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import CreateWorkOrderDialog from "./dialog/workorder/CreateWorkOrderDialog";
import api from "../../api/axiosConfig";
import CreateProjectDialog from "./dialog/project/CreateProjectDialog";
import {toast} from "react-toastify";
import ViewWorkOrderQuoteDialog from "../work_order/dialog/ViewWorkOrderQuoteDialog";
import WorkOrderDialog from "./dialog/workorder/WorkOrderDialog";
import EditWorkOrderDialog from "./dialog/workorder/EditWorkOrderDialog";

const ClientWorkOrder = (props) => {
  const selectedClientId = props.selectedclientId;

  const navigate = useNavigate();

  const [selectedWorkOrderId, setSelectedWorkOrderId] = React.useState(null);
  const [addModalShow, setAddModalShow] = React.useState(false);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [viewWorkOrderShow, setViewWorkOrderShow] = React.useState(false);
  const [workOrderList, setWorkOrderList] = useState([]);

  const getClientWorkOrders = async () => {
    if (typeof selectedClientId !== 'undefined' && selectedClientId) {
      try {
        const response = await api.get(`/api/work-orders?clientId=${selectedClientId}`);
        console.log(response.data);
        setWorkOrderList(response.data.content);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const updateWorkOrderStatus = async (workOrderId, status, toastMessage) => {
    try {
      const response = await api.put(`/api/work-orders/status/${workOrderId}`,
          {
            id: workOrderId,
            submitStatus: status
          }); // TODO try using body: JSON.Stringfy(data);
      toast.success(toastMessage);
      getClientWorkOrders();
    } catch (err) {
      console.log(err);
    }
  };

  const handleHideAddModal = () => {
    getClientWorkOrders();
    setAddModalShow(false);
  };

  const handleHideEditModal = () => {
    getClientWorkOrders();
    setEditModalShow(false);
  };

  const handleHideViewWorkOrder = () => {
    setViewWorkOrderShow(false);
  };

  const handleViewWorkOrder = (workOrderId) => {
    setSelectedWorkOrderId(workOrderId);
    setViewWorkOrderShow(true);
  };

  const handleEditWorkOrder = (workOrderId) => {
    setSelectedWorkOrderId(workOrderId);
    setEditModalShow(true);
  };

  const handleDeleteWorkOrder = (workOrderId) => {
    let toastMessage = "Work order DELETED";
    updateWorkOrderStatus(workOrderId, "DELETE", toastMessage);
  };

  const handleSubmitWorkOrder = (workOrderId) => {
    let toastMessage = "Work order SUBMITED";
    updateWorkOrderStatus(workOrderId, "SUBMIT", toastMessage);
  };

  const handleViewQuotation = (workOrderId) => {
    // let toastMessage = "Work order SUBMIT successfully";
    // updateWorkOrderStatus(workOrderId, "SUBMIT", toastMessage);
  };

  const handleAcceptQuotation = (workOrderId) => {
    let toastMessage = "Work order Quotation accepted";
    updateWorkOrderStatus(workOrderId, "CLIENT_ACCEPTED", toastMessage);
  };

  const handleRejectQuotation = (workOrderId) => {
    let toastMessage = "Work order Quotation rejected";
    updateWorkOrderStatus(workOrderId, "CLIENT_REJECTED", toastMessage);
  };

  useEffect(() => {
    getClientWorkOrders()
  }, [selectedClientId]);

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>Work Order</h2>
          </div>
          <div className="card-body border-0">
            <div className="add-button">
              <>
                <Button variant="btn btn-success" onClick={() => setAddModalShow(true)}>
                  Create Work Order
                </Button>

                <CreateWorkOrderDialog
                    show={addModalShow}
                    onHide={() => handleHideAddModal()}
                    selectedclientid={selectedClientId}
                />

                 <EditWorkOrderDialog
                    show={editModalShow}
                    onHide={() => handleHideEditModal()}
                    selectedclientid={selectedClientId}
                    selectedWorkOrderId={selectedWorkOrderId}
                />

                <WorkOrderDialog
                    show={viewWorkOrderShow}
                    onHide={() => handleHideViewWorkOrder()}
                    selectedWorkOrderId={selectedWorkOrderId}
                />
              </>
            </div>
          </div>
        </div>

        <table className="table table-bordered">
          <thead className="bg-black text-white">
          <tr>
            <td>Work Order Reference</td>
            <td>Project Name</td>
            <td>Project Code</td>
            <td>Created Date</td>
            <td>Status</td>
            <td>ACTION</td>
          </tr>
          </thead>
          <tbody>
          {
            workOrderList &&
            workOrderList.map((workOrder) => (
                <tr key={workOrder.id}>
                  <td>{workOrder.clientReference}</td>
                  <td>{workOrder.projectName}</td>
                  <td>{workOrder.projectCode}</td>
                  <td>{workOrder.createdDate}</td>
                  <td>{workOrder.status}</td>
                  <td>
                    <button type="button" onClick={() => {handleViewWorkOrder(workOrder.id)}}
                            className="btn btn-dark btn-sm">View
                    </button>
                    {(workOrder.status == 'DRAFT') &&
                      <>
                        &nbsp;
                        <button type="button" onClick={() => {handleEditWorkOrder(workOrder.id)}}
                                className="btn btn-success btn-sm">Edit
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {handleSubmitWorkOrder(workOrder.id)}}
                                className="btn btn-success btn-sm">Submit
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {handleDeleteWorkOrder(workOrder.id)}}
                                  className="btn btn-danger btn-sm">Delete
                        </button>
                      </>
                    }
                    {(workOrder.status == 'PENDING_QUOTE' || workOrder.status == 'PENDING_SUBMIT_TO_CLIENT' ) &&
                      <>
                        &nbsp;
                        <button type="button" onClick={() => {handleEditWorkOrder(workOrder.id)}}
                                className="btn btn-success btn-sm">Edit
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {handleDeleteWorkOrder(workOrder.id)}}
                                  className="btn btn-danger btn-sm">Delete
                        </button>
                      </>
                    }
                    {(workOrder.status == 'CLIENT_PENDING_ACCEPT') &&
                      <>
                        &nbsp;
                        <button type="button" onClick={() => {handleViewQuotation(workOrder.id)}}
                                className="btn btn-success btn-sm">View Quotation
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {handleEditWorkOrder(workOrder.id)}}
                                className="btn btn-success btn-sm">Edit
                        </button>
                        &nbsp;   <button type="button" onClick={() => {handleAcceptQuotation(workOrder.id)}}
                                className="btn btn-success btn-sm">Accept
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {handleRejectQuotation(workOrder.id)}}
                                className="btn btn-success btn-sm">Reject
                        </button>
                      </>
                    }

                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </div>
  )
};

export default ClientWorkOrder
