import React from 'react'
import Modal from 'react-bootstrap/Modal';
import AddAssignee from "./AddAssignee";

const AddAssigneeDialog = (props) => {
  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Assignee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <AddAssignee
                onHide={props.onHide}
                assigneeTypeList={props.assigneeTypeList}
            />
          }
        </Modal.Body>
      </Modal>
  )
}

export default AddAssigneeDialog
