import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import api from "../../api/axiosConfig";
import {toast} from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [id, idchange] = useState('');

  const validateInputData = () => {
    if (id === null || id === undefined || id.length === 0) {
      toast.error("Please provide the email");
      return false;
    }
    return true;
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if(validateInputData()) {
      try {
        const response = await api.post("/api/users/reset-password",
            {email: id}); // TODO try using body: JSON.Stringfy(data);
        console.log(response.data);
        navigate('/');
      } catch (err) {
        toast.error(err.response.data.errorDetails[0].message);
        console.log(err);
      }
    }
  };

  useEffect(() => {
  }, []);

  return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'}}>
        <form className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h2>Forgot Password</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Email Address <span className="text-danger">*</span></label>
                    <input value={id} onChange={e => idchange(e.target.value)} className="form-control"></input>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-success" onClick={handlesubmit}>Send Reset Code</button>
                  &nbsp;
                  <button className="btn btn-danger"  onClick={() => navigate("/login")}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default ForgotPassword
