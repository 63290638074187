import React, {useState} from 'react'
import api from "../../../../api/axiosConfig";
import DeleteClientDialog from "../client/DeleteClientDialog";
import DeleteProjectSectionDialog from "./DeleteProjectSectionDialog";

const EditProejctSection = (props) => {
  const addTableRows = (e) => {
    const rowsInput = {
      id: '',
      clientProjectId: '',
      sectionName: '',
      remarks: ''
    };
    props.setRowsData(oldArray => [...oldArray, rowsInput]);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const deleteTableRows = async () => {
    try {
      const rowsInput = [...props.rowsData];
      const response = await api.delete(`/api/project-sections/${rowsInput[selectedIndex].id}`);
      console.log(response.data);
      const rows = [...props.rowsData];
      rows.splice(selectedIndex, 1);
      props.setRowsData(rows);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (index, evnt) => {
    evnt.preventDefault();
    const {name, value} = evnt.target;
    const rowsInput = [...props.rowsData];
    rowsInput[index][name] = value;
    props.setRowsData(rowsInput);
  };

  const handleDeleteProjectSection = (index) => {
    setSelectedIndex(index);
    setDeleteModalShow(true);
  };

  const handleHideDeleteModal = () => {
    setDeleteModalShow(false);
  };

  return (
      <div className="container">
        <div className="row">
          <div>
            <>
              <DeleteProjectSectionDialog
                  show={deleteModalShow}
                  onHide={() => handleHideDeleteModal()}
                  deleteProjectSection={() => deleteTableRows()}
              />
            </>
          </div>
          <div>
            <table className="table">
              <thead>
              <tr>
                <th>Section Name</th>
                <th>Remarks</th>
                <th></th>
                <th>
                  {(props.rowsData.length === 0) &&
                  <button type="button" onClick={addTableRows}
                          className="btn btn-outline-success btn-sm">+
                  </button>
                  }
                </th>
              </tr>
              </thead>
              <tbody>
              {
                props.rowsData &&
                props.rowsData.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <input className="form-control" id="sectionName"  value={data.sectionName} onChange={(evnt) => (handleChange(index, evnt))}  name="sectionName"/>
                      </td>
                      <td>
                        <input className="form-control" id="remarks"  value={data.remarks} onChange={(evnt) => (handleChange(index, evnt))}  name="remarks"/>
                      </td>
                      <td>
                        <button type="button" onClick={() => (handleDeleteProjectSection(index))}
                                className="btn btn btn-outline-danger btn-sm">x
                        </button>
                      </td>
                      <td>
                        {(props.rowsData.length === (index + 1)) &&
                        <button type="button" onClick={addTableRows}
                                className="btn btn-outline-success btn-sm" autoFocus>+
                        </button>
                        }
                      </td>
                    </tr>
                ))
              }
              </tbody>
            </table>
          </div>
          <div className="col-sm-4">
          </div>
        </div>
      </div>
  )
};

export default EditProejctSection
