import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EditAssignee from "./EditAssignee";
import AddAssigneeDialog from "./AddAssigneeDialog";

const EditAssigneeDialog = (props) => {
  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Assignee
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <EditAssignee
                onHide={props.onHide}
                selectedAssigneeId={props.selectedAssigneeId}
                assigneeTypeList={props.assigneeTypeList}
            />
          }

        </Modal.Body>
      </Modal>
  )
}

export default EditAssigneeDialog
