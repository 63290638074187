import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import api from "../../api/axiosConfig";
import jwt from 'jwt-decode';
import {toast} from "react-toastify";

const Login = () => {
  const navigate = useNavigate();

  const [id, idchange] = useState('');
  const [password, pwchange] = useState('');

  const validateInputData = () => {
    if (id === null || id === undefined || id.length === 0) {
      toast.error("User name cannot be empty");
      return false;
    }
    if (password === null || password === undefined || password.length === 0) {
      toast.error("Password cannot be empty");
      return false;
    }
    return true;
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if(validateInputData()) {
      try {
        const response = await api.post("/pub/api/sign-in",
            {email: id, password: password}); // TODO try using body: JSON.Stringfy(data);
        console.log(response.data);
        const accessToken = jwt(response.data.accessToken);
        const refreshToken = jwt(response.data.refreshToken);
        console.log(">>>>>>>>>>>. " + JSON.stringify(accessToken, null, 2));
        console.log(">>>>>>>>>>>. " + JSON.stringify(accessToken, null, 2));

        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('appState', accessToken.role);
        localStorage.setItem('displayName', accessToken.name);
        localStorage.setItem('userId', accessToken.userId);

        navigate('/');
      } catch (err) {
        toast.error(err.response.data.errorDetails[0].message);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'}}>
        <form className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h2>Login</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>User Name <span className="text-danger">*</span></label>
                    <input value={id} onChange={e => idchange(e.target.value)} className="form-control"></input>
                  </div>

                  <div className="form-group">
                    <label>Password<span className="text-danger">*</span></label>
                    <input value={password} type="password" onChange={e => pwchange(e.target.value)} className="form-control"></input>
                  </div>

                </div>
                <div className="card-footer">
                  <button className="btn btn-success" onClick={handlesubmit}>Login</button>
                  &nbsp;
                  &nbsp;
                  <button className="btn btn-link" onClick={() => navigate("/forgot-password")}>Forgot Password</button>
                </div>
                {/*<div className="card-footer">*/}
                {/*  <div>*/}
                {/*    Need an account for your company?*/}
                {/*    &nbsp;*/}
                {/*    &nbsp;*/}
                {/*    <button className="btn btn-warning" onClick={() => navigate("/register-client")}>Register Company</button>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="card-footer">*/}
                {/*  <div>*/}
                {/*    Need an account for PEA/SRE/RE/RTO?*/}
                {/*    &nbsp;*/}
                {/*    &nbsp;*/}
                {/*    <button className="btn btn-warning" onClick={() => navigate("/register-assignee")}>Register</button>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default Login
