import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router";
import api from "../../../../api/axiosConfig";
import {toast} from "react-toastify";

const EditClient = (props) => {
  const selectedClientId = props.selectedclientid;

  const initialClientState = {
    id: "",
    companyName: "",
    emailAddress: "",
    address: "",
    officeTelNo: "",
    mobileNo: "",
  };

  const [client, setClient] = useState(initialClientState);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setClient({ ...client, [name]: value });
  };

  const getClient = async () => {
    try {
      const response = await api.get(`/api/clients/${selectedClientId}`);
      console.log(response.data);
      setClient(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const validateInputData = (client) => {
    if (client.companyName === null || client.companyName.trim() === "") {
      toast.error("Company name cannot be empty");
      return false;
    }
    if (client.emailAddress === null || client.emailAddress.trim() === "") {
      toast.error("Company email cannot be empty");
      return false;
    }
    if (client.address === null || client.address.trim() === "") {
      toast.error("Company address cannot be empty");
      return false;
    }
    if (client.officeTelNo === null || client.officeTelNo.trim() === "") {
      toast.error("Company ophone number cannot be empty");
      return false;
    }
    return true;
  };

  const saveClient = async (client) => {
    var data = {
      id: "",
      companyName: client.companyName,
      emailAddress: client.emailAddress,
      address: client.address,
      officeTelNo: client.officeTelNo,
      mobileNo: client.mobileNo,
    };

    console.log("input data" + client.name);
    console.log("data" + data.name);

    try {
      const response = await api.put(`/api/clients/${selectedClientId}`,
          {
            companyName: data.companyName,
            emailAddress: data.emailAddress,
            address: data.address,
            officeTelNo: data.officeTelNo,
            mobileNo: data.mobileNo,
          }); // TODO try using body: JSON.Stringfy(data);
      toast.success("Client updated successfully");
      console.log(response.data);
      props.onHide();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateClient = (e) => {
    e.preventDefault();
    if (validateInputData(client)) {
      saveClient(client);
      props.onHide();
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    props.onHide();
  };

  useEffect(() => {
    getClient();
    console.log("Selected id: " + selectedClientId);
  }, []);

  return (
      <div>
        <form className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="card border-0">
                <div className="card-body">
                  <div className="form-group">
                    <div className="center-content">
                      <h5>Company details</h5>
                    </div>
                    <label>Company Name <span className="text-danger">*</span></label>
                    <input className="form-control" id="companyName" value={client.companyName} onChange={handleInputChange} name="companyName"/>
                    <label>Company Email <span className="text-danger">*</span></label>
                    <input className="form-control" id="emailAddress" value={client.emailAddress} onChange={handleInputChange} name="emailAddress"/>
                    <label>Company Addess <span className="text-danger">*</span></label>
                    <input className="form-control" id="address" value={client.address} onChange={handleInputChange} name="address"/>
                    <label>Office Tel No <span className="text-danger">*</span></label>
                    <input className="form-control" id="officeTelNo" value={client.officeTelNo} onChange={handleInputChange}
                           name="officeTelNo"/>
                    <label>Mobile No</label>
                    <input className="form-control" id="mobileNo" value={client.mobileNo} onChange={handleInputChange}
                           name="mobileNo"/>
                    <br/>
                  </div>
                </div>
                <div className="card-footer border-0 center-content">
                  <button type="button" onClick={handleUpdateClient}
                          className="btn btn-success btn-sm">Save
                  </button>
                  &nbsp;
                  <button type="button" onClick={handleCancel}
                          className="btn btn-success btn-sm">Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default EditClient
