import axios from 'axios';
import { SetupInterceptors } from './SetupInterceptors'
import {baseURL, adminURL} from './AppConfig'

const http = axios.create({
  baseURL:baseURL,
  headers: {
    "ngrok-skip-browser-warning": "true",
    "Access-Control-Allow-Origin": adminURL
  }
});

SetupInterceptors(http);

export default http

