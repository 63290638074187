import React, {useState} from 'react'
import {toast} from "react-toastify";
import api from "../../api/axiosConfig";
import {useNavigate} from "react-router";

const RegisterClient = () => {
  const navigate = useNavigate();

  const initialClientState = {
    id: "",
    companyName: "",
    emailAddress: "",
    address: "",
    officeTelNo: "",
    mobileNo: "",
    userFullName: "",
    userEmailAddress: "",
    userMobileNo: "",
    password: "",
    confirmPassword: ""
  };

  const [client, setClient] = useState(initialClientState);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = event => {
    const {name, value} = event.target;
    setClient({...client, [name]: value});
  };

  const validateInputData = (client) => {
    if (client.companyName === null || client.companyName.trim() === "") {
      toast.error("Company name cannot be empty");
      return false;
    }
    if (client.emailAddress === null || client.emailAddress.trim() === "") {
      toast.error("Company email cannot be empty");
      return false;
    }
    if (client.address === null || client.address.trim() === "") {
      toast.error("Company address cannot be empty");
      return false;
    }
    if (client.officeTelNo === null || client.officeTelNo.trim() === "") {
      toast.error("Company ophone number cannot be empty");
      return false;
    }
    if (client.userFullName === null || client.userFullName.trim() === "") {
      toast.error("User full name cannot be empty");
      return false;
    }
    if (client.userEmailAddress === null || client.userEmailAddress.trim() === "") {
      toast.error("User email address cannot be empty");
      return false;
    }
    if (client.userMobileNo === null || client.userMobileNo.trim() === "") {
      toast.error("User mobile number cannot be empty");
      return false;
    }
    if (client.password === null || client.password.trim() === "") {
      toast.error("Password cannot be empty");
      return false;
    }
    if (client.password === null || client.password.trim() !== client.confirmPassword.trim()) {
      toast.error("Password does not match");
      return false;
    }
    return true;
  };

  const saveClient = async (client) => {
    var data = {
      id: "",
      companyName: client.companyName,
      emailAddress: client.emailAddress,
      address: client.address,
      officeTelNo: client.officeTelNo,
      mobileNo: client.mobileNo,
      userFullName: client.userFullName,
      userEmailAddress: client.userEmailAddress,
      userMobileNo: client.userMobileNo,
      password: client.password,
      confirmPassword: client.confirmPassword,
    };

    console.log("input data" + client.companyName);
    console.log("data" + data.companyName);

    try {
      const response = await api.post("/pub/register/register-client",
          {
            id: data.id,
            companyName: data.companyName,
            emailAddress: data.emailAddress,
            address: data.address,
            officeTelNo: data.officeTelNo,
            mobileNo: data.mobileNo,
            userFullName: data.userFullName,
            userEmailAddress: data.userEmailAddress,
            userMobileNo: data.userMobileNo,
            password: data.password,
            confirmPassword: data.confirmPassword,
          }); // TODO try using body: JSON.Stringfy(data);
      toast.success("Client registered successfully. Please check your email once approved your profile");
      console.log(response.data);
      navigate("/login");
    } catch (err) {
      toast.error(err.response.data.errorDetails[0].message);
      console.log(err);
    }
  };

  const handleAddClient = (e) => {
    e.preventDefault();
    if (validateInputData(client)) {
      saveClient(client);
    }
  };

  return (
      <div>
        <form className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="card border-0">
                <div className="card-body">
                  <div className="form-group">
                    <div className="center-content">
                      <h5>Company details</h5>
                    </div>
                    <label>Company Name <span className="text-danger">*</span></label>
                    <input className="form-control" id="companyName" value={client.companyName} onChange={handleInputChange} name="companyName"/>
                    <label>Company Email <span className="text-danger">*</span></label>
                    <input className="form-control" id="emailAddress" value={client.emailAddress} onChange={handleInputChange} name="emailAddress"/>
                    <label>Company Addess <span className="text-danger">*</span></label>
                    <input className="form-control" id="address" value={client.address} onChange={handleInputChange} name="address"/>
                    <label>Office Tel No <span className="text-danger">*</span></label>
                    <input className="form-control" id="officeTelNo" value={client.officeTelNo} onChange={handleInputChange}
                           name="officeTelNo"/>
                    <label>Mobile No</label>
                    <input className="form-control" id="mobileNo" value={client.mobileNo} onChange={handleInputChange}
                           name="mobileNo"/>
                    <br/>
                    <div className="center-content">
                      <h5>User details</h5>
                    </div>

                    <label>User full Name <span className="text-danger">*</span></label>
                    <input className="form-control" id="userFullName" value={client.userFullName} onChange={handleInputChange} name="userFullName"/>
                    <label>User Email <span className="text-danger">*</span></label>
                    <input className="form-control" id="userEmailAddress" value={client.userEmailAddress} onChange={handleInputChange}
                           name="userEmailAddress"/>
                    <label>User Mobile <span className="text-danger">*</span></label>
                    <input className="form-control" id="userMobileNo" value={client.userMobileNo} onChange={handleInputChange} name="userMobileNo"/>

                    <label>Password<span className="text-danger">*</span></label>
                    <input className="form-control" id="password" value={client.password} type="password" onChange={handleInputChange}
                           name="password"/>
                    <label>Confirm Password<span className="text-danger">*</span></label>
                    <input className="form-control" id="confirmPassword" value={client.confirmPassword} type="password" onChange={handleInputChange}
                           name="confirmPassword"/>

                  </div>
                </div>
                <div className="center-content">
                  <button type="button" onClick={handleAddClient}
                          className="btn btn-success btn-sm">Register
                  </button>
                  &nbsp;
                  <button type="button" onClick={() => navigate("/login")}
                          className="btn btn-success btn-sm">Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default RegisterClient
