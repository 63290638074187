import React, {useEffect} from 'react'
import {useNavigate} from "react-router";

const LogOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("LogOut submit");
    localStorage.clear();
    navigate('/');
  }, []);

  return (
      <div></div>
  )
};

export default LogOut
