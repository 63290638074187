import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import api from "../../api/axiosConfig";
import {toast} from "react-toastify";

const ResetPassword = () => {
  let params = useParams();
  const userId = params.userId;
  const verificationCode = params.verificationCode;

  const navigate = useNavigate();

  const [password, pwchange] = useState('');
  const [confirmPassword, confirmPwchange] = useState('');

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (validateInputData()) {
      try {
        const response = await api.put("/api/users/reset-password",
            {
              userId: userId,
              verificationCode: verificationCode,
              password: password,
              confirmPassword: confirmPassword
            }); // TODO try using body: JSON.Stringfy(data);
        console.log(response.data);
        navigate('/');
      } catch (err) {
        toast.error(err.response.data.errorDetails[0].message);
        console.log(err);
      }
    }
  };

  const validateInputData = () => {
    if (password === undefined || password === null || password.length === 0) {
      toast.error("Password cannot be empty");
      return false;
    }else if(password.trim() !== confirmPassword.trim()){
      toast.error("Password does not match");
      return false;
    }
    return true;
  };

  useEffect(() => {
  }, []);

  return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'}}>
        <form className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h2>Reset Password</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Password<span className="text-danger">*</span></label>
                    <input value={password} type="password" onChange={e => pwchange(e.target.value)} className="form-control"></input>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password<span className="text-danger">*</span></label>
                    <input value={confirmPassword} type="password" onChange={e => confirmPwchange(e.target.value)} className="form-control"></input>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-success" onClick={handlesubmit}>Reset Password</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default ResetPassword
