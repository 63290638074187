import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router";
import api from "../../api/axiosConfig";
import Figure from 'react-bootstrap/Figure';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Project from "./Project";
import ClientWorkOrder from "./ClientWorkOrder";
import ClientUser from "./dialog/user/ClientUser";

const ViewClient = () => {
  let params = useParams();
  const selectedClientUserId = params.selectedClientId;

  const initialClientState = {
    id: "",
    primaryUserId: "",
    companyName: "",
    emailAddress: "",
    address: "",
    officeTelNo: "",
    mobileNo: "",
  };

  const [client, setClient] = useState(initialClientState);

  const getClient = async () => {
    try {
      const response = await api.get(`/api/clients/user/${selectedClientUserId}`);
      console.log(response.data);
      setClient(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClient();
    console.log("Selected id: " + selectedClientUserId);
  }, []);

  return (
      <Container>
        <Row className="text-center">
          <Col><h3>{client.companyName}</h3></Col>
        </Row>
        <Row className="mt-2">
          <Col>
            {
              <>
                <Row className="flex-column h-25 g-0">
                  <Col>
                    {/*<hr/>*/}
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <h5>Company Address</h5>
                  </Col>
                  <Col>
                    <h5>{client.address}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <h5>Company Office tel</h5>
                  </Col>
                  <Col>
                    <h5>{client.officeTelNo}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <h5>Company Mobile No</h5>
                  </Col>
                  <Col>
                    <h5>{client.mobileNo}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <h5>Company Email</h5>
                  </Col>
                  <Col>
                    <h5>{client.emailAddress}</h5>
                  </Col>
                </Row>
              </>
            }
          </Col>
          <Col xs={5} className="text-end">
            <Figure>
              <Figure.Image
                  width={192}
                  height={192}
                  alt="171x180"
                  src="/logo192.png"
              />
            </Figure>
          </Col>
        </Row>
        <Row>
          <Col>
            {/*<hr/>*/}
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
                defaultActiveKey="projects"
                id="client_activity"
                className="mb-3">
              <Tab eventKey="projects" title="Projects">
                <Project
                    selectedclientId={client.id}
                />
              </Tab>
              <Tab eventKey="work_orders" title="Work Orders">
                <ClientWorkOrder
                    selectedclientId={client.id}
                />
              </Tab>
              <Tab eventKey="users" title="Manage users">
                <ClientUser
                    selectedclientId={client.id}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
  )
}

export default ViewClient
