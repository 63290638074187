import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router";
import api from "../../../../api/axiosConfig";
import {toast} from "react-toastify";

const EditAssignee = (props) => {
  // let params = useParams();
  const selectedAssigneeId = props.selectedAssigneeId;

  // const navigate = useNavigate();

  const initialAssigneeState = {
    id: "",
    name: "",
    mobileNo: "",
    emailAddress: "",
    assigneeTypeId: "",
    password: "",
    confirmPassword: ""
  };

  const [assignee, setAssignee] = useState(initialAssigneeState);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setAssignee({ ...assignee, [name]: value });
  };

  const getAssignee = async () => {
    try {
      const response = await api.get(`/api/assignees/${selectedAssigneeId}`);
      console.log(response.data);
      setAssignee(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const validateInputData = (assignee) => {
    if (assignee.name === null || assignee.name.trim() === "") {
      toast.error("Name cannot be empty");
      return false;
    }
    if (assignee.assigneeTypeId === null || assignee.assigneeTypeId.trim() === "") {
      toast.error("Assignee type cannot be empty");
      return false;
    }
    if (assignee.mobileNo === null || assignee.mobileNo.trim() === "") {
      toast.error("Mobile phone number cannot be empty");
      return false;
    }
    if (assignee.emailAddress === null || assignee.emailAddress.trim() === "") {
      toast.error("Email cannot be empty");
      return false;
    }
    if (assignee.password !== assignee.confirmPassword) {
      toast.error("Password does not match");
      return false;
    }
    return true;
  };

  const saveAssignee = async (assignee) => {
    var data = {
      id: "",
      name: assignee.name,
      emailAddress: assignee.emailAddress,
      mobileNo: assignee.mobileNo,
      assigneeTypeId: assignee.assigneeTypeId,
      password: assignee.password,
      confirmPassword: assignee.confirmPassword
    };

    console.log("input data" + assignee.name);
    console.log("data" + data.name);

    try {
      const response = await api.put(`/api/assignees/${selectedAssigneeId}`,
          {
            id: data.id,
            name: data.name,
            assigneeTypeId:data.assigneeTypeId,
            emailAddress:data.emailAddress,
            mobileNo:data.mobileNo,
            password:data.password,
            confirmPassword:data.confirmPassword
          }); // TODO try using body: JSON.Stringfy(data);
      toast.success("Assignee updated successfully");
      // navigate("/assignee");
      console.log(response.data);
      props.onHide();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateAssignee = (e) => {
    e.preventDefault();
    if (validateInputData(assignee)) {
      saveAssignee(assignee);
      props.onHide();
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    // navigate("/assignee");
    props.onHide();
  };

  useEffect(() => {
    getAssignee();
    console.log("Selected id: " + selectedAssigneeId);
  }, []);

  return (
      <div>
        <form className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="card border-0">
                <div className="card-body">
                  <div className="form-group">
                    <label>Full Name</label>
                    <input className="form-control" id="name"  value={assignee.name} onChange={handleInputChange} name="name"/>
                    <label>Email</label>
                    <input className="form-control" id="emailAddress" value={assignee.emailAddress} onChange={handleInputChange} name="emailAddress"/>
                    <label>Mobile</label>
                    <input className="form-control" id="mobileNo" value={assignee.mobileNo} onChange={handleInputChange} name="mobileNo"/>

                    <label>Password<span className="text-danger">*</span></label>
                    <input className="form-control" id="password" value={assignee.password} type="password" onChange={handleInputChange} name="password"/>
                    <label>Confirm Password<span className="text-danger">*</span></label>
                    <input className="form-control" id="confirmPassword" value={assignee.confirmPassword} type="password" onChange={handleInputChange} name="confirmPassword"/>

                    <label>Assignee type</label>
                    <select className="form-control" id="assigneeTypeId" value={assignee.assigneeTypeId} onChange={handleInputChange} name="assigneeTypeId">
                      <option value=""></option>
                      {
                        props.assigneeTypeList &&
                        props.assigneeTypeList.map((assigneeType) => (
                            <option key={assigneeType.id} value={assigneeType.id}>{assigneeType.type}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="card-footer border-0 center-content">
                  <button type="button" onClick={handleUpdateAssignee}
                          className="btn btn-success btn-sm">Update
                  </button>
                  &nbsp;
                  <button type="button" onClick={handleCancel}
                          className="btn btn-success btn-sm">Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default EditAssignee
