import React, {useState} from 'react'

const WorkOderQuoteDetailSettings = (props) => {
  const selectedWorkOrderId = props.selectedWorkOrderId;

  const initialWorkOrderState = {
    id: "",
    companyLine1: "",
    companyLine2: "",
    companyPhone: "",
    companyEmail: "",
    addressLine1: "",
    addressLine2: "",
    toCompany: "",
    projectName: "",
    headerLine: "",
    paragraphLine1: "",
    paragraphLine2: "",
    preparedBy: "",
    preparedByPosition: "",
    acceptedBy: "",
  };

  const [workOrder, setWorkOrder] = useState(initialWorkOrderState);

  const handleInputOtRateChange = event => {
    const {name, value} = event.target;
    setWorkOrder({...workOrder, [name]: value});
  };

  return (
      <div className="container">
        <div className="row">
          <div className="offset-lg-0 col-lg-12">
            <div className="card border-0">
              <div className="card-header border-0 center-content">
                <h4>View Work Order</h4>
              </div>
              <div className="card-body">

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Company Line 1</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="companyLine1" value={workOrder.companyLine1} onChange={handleInputOtRateChange}
                           name="companyLine1"/>
                  </div>
                  <div className="col-sm-2">
                    <h6>Company Line 2</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="companyLine2" value={workOrder.companyLine2} onChange={handleInputOtRateChange}
                           name="companyLine2"/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Company Phone</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="companyPhone" value={workOrder.companyPhone} onChange={handleInputOtRateChange}
                           name="companyPhone"/>
                  </div>
                  <div className="col-sm-2">
                    <h6>Company Email</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="companyEmail" value={workOrder.companyEmail} onChange={handleInputOtRateChange}
                           name="companyEmail"/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Address Line 1</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="addressLine1" value={workOrder.addressLine1} onChange={handleInputOtRateChange}
                           name="addressLine1"/>
                  </div>
                  <div className="col-sm-2">
                    <h6>Address Line 2</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="addressLine2" value={workOrder.addressLine2} onChange={handleInputOtRateChange}
                           name="addressLine2"/>
                  </div>
                </div>

                <hr/>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>To </h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="toCompany" value={workOrder.toCompany} onChange={handleInputOtRateChange}
                           name="toCompany"/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Project Name</h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="projectName" value={workOrder.projectName} onChange={handleInputOtRateChange}
                           name="projectName"/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Header</h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="headerLine" value={workOrder.headerLine} onChange={handleInputOtRateChange}
                           name="headerLine"/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Paragraph Line 1</h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="paragraphLine1" value={workOrder.paragraphLine1} onChange={handleInputOtRateChange}
                           name="paragraphLine1"/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Paragraph Line 2</h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="paragraphLine2" value={workOrder.paragraphLine2} onChange={handleInputOtRateChange}
                           name="paragraphLine2"/>
                  </div>
                </div>

                <hr/>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Prepared by</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="preparedBy" value={workOrder.preparedBy} onChange={handleInputOtRateChange}
                           name="preparedBy"/>
                  </div>
                  <div className="col-sm-2">
                    <h6>Prepared by Position</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="preparedByPosition" value={workOrder.preparedByPosition} onChange={handleInputOtRateChange}
                           name="preparedByPosition"/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Accepted by</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="acceptedBy" value={workOrder.acceptedBy} onChange={handleInputOtRateChange}
                           name="acceptedBy"/>
                  </div>
                </div>

              </div>
              <div className="card-footer border-0 center-content">

              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default WorkOderQuoteDetailSettings

