import React, {useState} from 'react'
import api from "../../../../api/axiosConfig";
import {toast} from 'react-toastify'
import DatePicker from "react-datepicker";
import {Row} from "react-bootstrap";

const AddAssignee = (props) => {
  // const navigate = useNavigate();

  const initialAssigneeState = {
    id: "",
    name: "",
    mobileNo: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
    assigneeTypeId: "",
    registrationNo: "",
    expiryDate: ""
  };

  const [assignee, setAssignee] = useState(initialAssigneeState);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setAssignee({ ...assignee, [name]: value });
  };

  const validateInputData = (assignee) => {
    if (assignee.name === null || assignee.name.trim() === "") {
      toast.error("Full Name cannot be empty");
      return false;
    }
    if (assignee.mobileNo === null || assignee.mobileNo.trim() === "") {
      toast.error("Mobile phone number cannot be empty");
      return false;
    }
    if (assignee.emailAddress === null || assignee.emailAddress.trim() === "") {
      toast.error("Email cannot be empty");
      return false;
    }
    if (assignee.password === null || assignee.password.trim() === "") {
      toast.error("Password cannot be empty");
      return false;
    }
    if (assignee.password === null || assignee.password.trim() !== assignee.confirmPassword.trim()) {
      toast.error("Password does not match");
      return false;
    }
    if (assignee.assigneeTypeId === null || assignee.assigneeTypeId.trim() === "") {
      toast.error("Assignee type cannot be empty");
      return false;
    }
    if (assignee.registrationNo === null || assignee.registrationNo.trim() === "") {
      toast.error("Assignee type certificate Registration No cannot be empty");
      return false;
    }
    if (assignee.expiryDate === null) {
      toast.error("Assignee type certificate Expiry Date cannot be empty");
      return false;
    }
    return true;
  };

  const saveAssignee = async (assignee) => {
    var data = {
      id: "",
      name: assignee.name,
      emailAddress: assignee.emailAddress,
      mobileNo: assignee.mobileNo,
      password: assignee.password,
      confirmPassword: assignee.confirmPassword,
      assigneeTypeId: assignee.assigneeTypeId,
      registrationNo: assignee.registrationNo,
      expiryDate: assignee.expiryDate
    };

    console.log("input data: " + assignee.name);
    console.log("data: " + data.name);

    try {
      const response = await api.post("/api/assignees",
          {
            id: data.id,
            name: data.name,
            emailAddress:data.emailAddress,
            mobileNo:data.mobileNo,
            password:data.password,
            confirmPassword:data.confirmPassword,
            assigneeTypeId:data.assigneeTypeId,
            registrationNo:data.registrationNo,
            expiryDate:data.expiryDate
          }); // TODO try using body: JSON.Stringfy(data);

      toast.success("Assignee saved successfully");
      console.log(response.data);
      props.onHide();
    } catch (err) {
      toast.error(err.response.data.errorDetails[0].message);
      console.log(err);
    }
  };

  const handleSingleDate = (update) => {
    setAssignee({ ...assignee, ['expiryDate']: update });
  };

  const handleAddAssignee = (e) => {
    e.preventDefault();
    if (validateInputData(assignee)) {
      saveAssignee(assignee);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    props.onHide();
  };

  return (
      <div>
        <form className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="card border-0">
                <div className="card-body">
                  <div className="form-group">
                    <label>Full Name <span className="text-danger">*</span></label>
                    <input className="form-control" id="name"  value={assignee.name} onChange={handleInputChange} name="name"/>
                    <label>Email <span className="text-danger">*</span></label>
                    <input className="form-control" id="emailAddress" value={assignee.emailAddress} onChange={handleInputChange} name="emailAddress"/>
                    <label>Mobile <span className="text-danger">*</span></label>
                    <input className="form-control" id="mobileNo" value={assignee.mobileNo} onChange={handleInputChange} name="mobileNo"/>

                    <label>Password<span className="text-danger">*</span></label>
                    <input className="form-control" id="password" value={assignee.password} type="password" onChange={handleInputChange} name="password"/>
                    <label>Confirm Password<span className="text-danger">*</span></label>
                    <input className="form-control" id="confirmPassword" value={assignee.confirmPassword} type="password" onChange={handleInputChange} name="confirmPassword"/>

                    <label>Assignee type <span className="text-danger">*</span></label>
                    <select className="form-control" id="assigneeTypeId" value={assignee.assigneeTypeId} onChange={handleInputChange} name="assigneeTypeId">
                      <option value=""> </option>
                      {
                        props.assigneeTypeList &&
                        props.assigneeTypeList.map((assigneeType) => (
                            <option value={assigneeType.id}>{assigneeType.type}</option>
                        ))
                      }
                    </select>
                    <label>Certificate Registration No<span className="text-danger">*</span></label>
                    <input className="form-control" id="registrationNo"  value={assignee.registrationNo} onChange={handleInputChange} name="registrationNo"/>
                    <label>Certificate Expiry Date<span className="text-danger">*</span></label>
                    <DatePicker
                        className="form-control"
                        selectsRange={false}
                        selected={assignee.expiryDate}
                        onChange={(update) => {
                           handleSingleDate(update)
                        }}
                        dateFormat="dd MMMM yyyy"
                        selectsDisabledDaysInRange={false}
                        style={{width: '100%'}}
                    />
                  </div>
                </div>
                <div className="card-footer border-0 center-content">
                  <button type="button" onClick={handleAddAssignee}
                          className="btn btn-success btn-sm">Add
                  </button>
                  &nbsp;
                  <button type="button" onClick={handleCancel}
                          className="btn btn-success btn-sm">Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default AddAssignee
