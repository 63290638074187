import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import api from "../../api/axiosConfig";
import Button from "react-bootstrap/Button";
import EditClientDialog from "./dialog/client/EditClientDialog";
import AddClientDialog from "./dialog/client/AddClientDialog";
import Spinner from "react-bootstrap/Spinner";
import DeleteClientDialog from "./dialog/client/DeleteClientDialog";

const Client = () => {
  const navigate = useNavigate();

  const [selectedClientId, setSelectedClientId] = React.useState(null);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [addModalShow, setAddModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const [clientList, setClientList] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = React.useState(false);

  const getClients = async () => {
    setIsClientsLoading(true);
    try {
      const response = await api.get("/api/clients");
      console.log(response.data);
      setClientList(response.data.content);
    } catch (err) {
      console.log(err);
    } finally {
      setIsClientsLoading(false);
    }
  };

  const deleteClient = async () => {
    try {
      const response = await api.delete(`/api/clients/${selectedClientId}`);
      console.log(response.data);
      getClients();
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewClient = (primaryUserId) => {
    navigate(`/client/view/${primaryUserId}`);
  };

  const handleUpdateClient = (clientId) => {
    setSelectedClientId(clientId);
    setEditModalShow(true);
  };

  const handleHideAddModal = () => {
    getClients();
    setAddModalShow(false);
  };

  const handleHideEditModal = () => {
    getClients();
    setEditModalShow(false);
  };

  const handleHideDeleteModal = () => {
    getClients();
    setDeleteModalShow(false);
  };

  const handleDeleteClient = (clientId) => {
    setSelectedClientId(clientId);
    setDeleteModalShow(true);
  };

  useEffect(() => {
    getClients()
  }, []);

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>Clients</h2>
          </div>
          <div className="card-body border-0">
            <div className="add-button">
              <>
                <Button variant="btn btn-success" onClick={() => setAddModalShow(true)}>
                  Add Client
                </Button>

                < AddClientDialog
                    show={addModalShow}
                    onHide={() => handleHideAddModal()}
                />

                <EditClientDialog
                    show={editModalShow}
                    onHide={() => handleHideEditModal()}
                    selectedclientid={selectedClientId}
                />

                <DeleteClientDialog
                    show={deleteModalShow}
                    onHide={() => handleHideDeleteModal()}
                    deleteClient={() => deleteClient()}
                />
              </>
            </div>
          </div>
        </div>

        <table className="table table-bordered">
          <thead className="bg-black text-white">
          <tr>
            {/*<td>ID</td>*/}
            <td>NAME</td>
            <td>ADDRESS</td>
            <td>OFFICE TEL</td>
            <td>OFFICE MOBILE</td>
            <td>EMAIL</td>
            <td>ACTION</td>
          </tr>
          </thead>
          <tbody>
          {
            isClientsLoading ? (
                <tr>
                  <td rowSpan="1" colSpan="6">
                    <div className="text-center py-6">
                      <Spinner animation="border"/>
                    </div>
                  </td>
                </tr>
            ) : clientList && clientList.length > 0 ?
                (clientList.map((client) => (
                    <tr key={client.id}>
                      {/*<td>{client.id}</td>*/}
                      <td>{client.companyName}</td>
                      <td>{client.address}</td>
                      <td>{client.officeTelNo}</td>
                      <td>{client.mobileNo}</td>
                      <td>{client.emailAddress}</td>
                      <td>
                        <button type="button" onClick={() => {
                          handleViewClient(client.primaryUserId)
                        }}
                                className="btn btn-dark btn-sm">View
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {
                          handleUpdateClient(client.id)
                        }}
                                className="btn btn-success btn-sm">Edit
                        </button>
                        &nbsp;
                        <button type="button" onClick={() => {
                          handleDeleteClient(client.id)
                        }}
                                className="btn btn-danger btn-sm">Delete
                        </button>
                      </td>
                    </tr>
                ))) : (
                    <tr>
                      <td colSpan="6">
                        <h5 className="text-danger text-center">No registered clients found</h5>
                      </td>
                    </tr>
                )
          }
          </tbody>
        </table>
      </div>
  )
}

export default Client
