import React, {useEffect, useState} from 'react'
import api from "../../../../api/axiosConfig";
import {toast} from "react-toastify";

const AssignWorkOrder = (props) => {
	const selectedAssigneeId = props.selectedAssigneeId;

	const [workOrderItemList, setWorkOrderItemList] = useState([]);

	const getClientWorkOrderItems = async () => {
		try {
			const response = await api.get(`/api/work-order-schedule-sub/assign-pending?assigneeId=${selectedAssigneeId}`);
			console.log(response.data);
			setWorkOrderItemList(response.data.content);
		} catch (err) {
			console.log(err);
		}
	};

	const assignSchedule = async (workOrderScheduleId) => {
		var data = {
			id: "",
			assigneeId: selectedAssigneeId
		};

		try {
			const response = await api.put(`/api/work-order-schedule-sub/assign/${workOrderScheduleId}`,
				{id: data.id, assigneeId: data.assigneeId}); // TODO try using body: JSON.Stringfy(data);
			toast.success("Schedule assigned successfully");
			getClientWorkOrderItems();
			console.log(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	const handleGetWorkOrderSchedule = (workOrderScheduleId) => {
		assignSchedule(workOrderScheduleId);
	};

	const handleCancel = (e) => {
		e.preventDefault();
		props.onHide();
	};

	useEffect(() => {
		// getWorkOrder();
		getClientWorkOrderItems();
		console.log("Selected id: " + selectedAssigneeId);
	}, []);

	function stringDateFormat(dateStr) {
		const options = {month: 'long', day: 'numeric', year: 'numeric'};
		const date = new Date(dateStr);
		return date.toLocaleDateString('en-SG', options);
	}

	function stringTimeFormat(dateStr) {
		var date = new Date(dateStr);
		return new Intl.DateTimeFormat('en-SG', {timeStyle: 'short', timeZone: 'Asia/Singapore', hour12: true}).format(date)
	}

	return (
		<div className="container">
			<div className="row">
				<div className="offset-lg-0 col-lg-12">
					<div className="card border-0">
						<div className="card-header border-0 center-content">
							<h4>Get Work Order</h4>
						</div>
						<div className="card-body">
							<div>
								<table className="table table-bordered table-hover">
									<thead className="align-middle table-danger">
									<tr>
										<td>Assignee Type</td>
										<td>Purpose</td>
										<td>Scheduled Date</td>
										<td>Time slot</td>
										<td>Action</td>
									</tr>
									</thead>
									<tbody>
									{
										workOrderItemList &&
										workOrderItemList.map((workOrderItem) => (
											<tr key={workOrderItem.id} className={workOrderItem.scheduleType === 'PRIMARY' ? 'table-dark' : 'table-warning'}>
												<td>{workOrderItem.assigneeType}</td>
												<td>{workOrderItem.projectSection}</td>
												<td>{stringDateFormat(workOrderItem.scheduleDate)}</td>
												<td>{stringTimeFormat(workOrderItem.startTime) + " - " + stringTimeFormat(workOrderItem.endTime)}</td>
												<td>
													{(workOrderItem.status === 'NOT_ASSIGNED' && workOrderItem.scheduleType === 'SECONDARY') &&
													<button type="button" onClick={() => {
														handleGetWorkOrderSchedule(workOrderItem.id)
													}}
													        className="btn btn-success btn-sm">Get
													</button>
													}
													{(workOrderItem.status === 'NOT_ASSIGNED' && workOrderItem.scheduleType === 'PRIMARY') &&
													<button type="button" onClick={() => {
														handleGetWorkOrderSchedule(workOrderItem.id)
													}}
													        className="btn btn-success btn-sm">Get
													</button>
													}
												</td>
											</tr>
										))
									}
									</tbody>
								</table>
							</div>
						</div>
						<div className="card-footer border-0 center-content">
							<button type="button" onClick={handleCancel}
							        className="btn btn-success btn-sm">Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default AssignWorkOrder
