import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DeleteProjectSectionDialog = (props) => {

  const handleDeleteProjectSection = () => {
    props.deleteProjectSection();
    props.onHide();
  };

  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Project Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This will delete the project section permanently. Do you want to delete project section?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteProjectSection}>Delete</Button>
          <Button variant="success" onClick={props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
  )
};

export default DeleteProjectSectionDialog
