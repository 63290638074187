import './App.css';
import Layout from './components/Layout';
import {Route, Routes} from 'react-router-dom';
import Home from './components/home/Home';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Client from "./components/client/Client";
import Assignee from "./components/assignee/Assignee";
import {ToastContainer} from 'react-toastify'
import ViewAssignee from "./components/assignee/ViewAssignee";
import AllWorkOrder from "./components/work_order/AllWorkOrder";
import AllWorkOrderTimeSheet from "./components/time_sheet/AllWorkOrderTimeSheet";
import AllActiveProjects from "./components/time_sheet/AllActiveProjects";
import WorkOrderEditTimeSheet from "./components/time_sheet/WorkOrderEditTimeSheet";
import ViewClient from "./components/client/ViewClient";
import Login from "./components/login/Login";
import LogOut from "./components/login/LogOut";
import RegisterClient from "./components/login/RegisterClient";
import RegisterAssignee from "./components/login/RegisterAssignee";
import ForgotPassword from "./components/login/ForgotPassword";
import ResetPassword from "./components/login/ResetPassword";
import EditWorkOrderSchedule from "./components/work_order/dialog/schedule/EditWorkOrderSchedule";
import WorkOderQuoteDetailSettings from "./components/setting/WorkOderQuoteDetailSettings";
import VerifyExtEmail from "./components/login/verifyExtEmail";

function App() {
	return (
		<div className="App">
			<Header/>
			<div>
				<Routes>
					<Route path="/" element={<Layout/>}>
						<Route path="/" element={<Home/>}></Route>

						<Route path="/login" element={<Login/>}></Route>
						<Route path="/register-client" element={<RegisterClient/>}></Route>
						<Route path="/register-assignee" element={<RegisterAssignee/>}></Route>
						<Route path="/verify-ext-email/:userId/:verificationCode" element={<VerifyExtEmail/>}></Route>
						<Route path="/forgot-password" element={<ForgotPassword/>}></Route>
						<Route path="/reset-password/:userId/:verificationCode" element={<ResetPassword/>}></Route>
						<Route path="/logout" element={<LogOut/>}></Route>

						<Route path="/work-order" element={<AllWorkOrder/>}></Route>
						<Route path="/work-order/edit/:selectedWorkOrderId" element={<EditWorkOrderSchedule/>}></Route>

						<Route path="/time-sheet" element={<AllActiveProjects/>}></Route>
						{/*<Route path="/time-sheet" element={<AllWorkOrderTimeSheet/>}></Route>*/}
						<Route path="/time-sheet/edit/:selectedProjectId/:selectedWorkOrderId" element={<WorkOrderEditTimeSheet/>}></Route>

						<Route path="/client" element={<Client/>}></Route>
						{/*<Route path="/client/create" element={<AddClient/>}></Route>*/}
						{/*<Route path="/client/edit/:selectedClientId" element={<EditClient/>}></Route>*/}
						<Route path="/client/view/:selectedClientId" element={<ViewClient/>}></Route>

						<Route path="/assignee" element={<Assignee/>}></Route>
						{/*<Route path="/assignee/create" element={<AddAssignee/>}></Route>*/}
						{/*<Route path="/assignee/edit/:selectedAssigneeId" element={<EditAssignee/>}></Route>*/}
						<Route path="/assignee/view/:selectedAssigneeId" element={<ViewAssignee/>}></Route>

                        <Route path="/settings" element={<WorkOderQuoteDetailSettings/>}></Route>

                        <Route path="*" element={<Home/>}></Route>
					</Route>
				</Routes>
			</div>
			<Footer/>
			<ToastContainer></ToastContainer>
		</div>
	);
}

export default App;
