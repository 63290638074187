import React, {useEffect, useState} from 'react'
import Button from "react-bootstrap/Button";
import AssignWorkOrderDialog from "./dialog/workorder/AssignWorkOrderDialog";
import api from "../../api/axiosConfig";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";

const SubmitTimeSheet = (props) => {
  const selectedAssigneeId = props.selectedAssigneeId;

  const [modalShow, setModalShow] = React.useState(false);
  const [timeSheetEntryList, setTimeSheetEntryList] = useState([]);

  const getAssignedWorkOrderSchedule = async () => {
    try {
      const response = await api.get(`/api/work-order-time-sheet?assigneeId=${selectedAssigneeId}`);
      console.log(response.data);
      setTimeSheetEntryList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTimeSheetEntry = async (index) => {
    console.log("Index: " + index);

    try {
      const response = await api.put(`/api/work-order-time-sheet/${timeSheetEntryList[index]['id']}`,
          {
            id: timeSheetEntryList[index]['id'],
            startTime: (new Date(timeSheetEntryList[index]['startTime'])).toISOString(),
            endTime: (new Date(timeSheetEntryList[index]['endTime'])).toISOString()
          }); // TODO try using body: JSON.Stringfy(data);

      console.log("Index: " + index);
      const tmpTimeSheetEntryList = [...timeSheetEntryList];
      tmpTimeSheetEntryList[index]['submitStatus'] = response.data.submitStatus;
      tmpTimeSheetEntryList[index]['disabled'] = response.data.disabled;
      setTimeSheetEntryList(tmpTimeSheetEntryList);

      toast.success("Time sheet entry updated successfully");
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getTimeSheetEntry = async (index) => {
    console.log("Index: " + index);

    try {
      const response = await api.get(`/api/work-order-time-sheet/${timeSheetEntryList[index]['id']}`); // TODO try using body: JSON.Stringfy(data);
      console.log("Index: " + index);
      const tmpTimeSheetEntryList = [...timeSheetEntryList];
      tmpTimeSheetEntryList[index]['submitStatus'] = response.data.submitStatus;
      tmpTimeSheetEntryList[index]['disabled'] = response.data.disabled;
      tmpTimeSheetEntryList[index]['startTime'] = response.data.startTime;
      tmpTimeSheetEntryList[index]['endTime'] = response.data.endTime;
      setTimeSheetEntryList(tmpTimeSheetEntryList);
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditTimeSheet = (index) => {
    const tmpTimeSheetEntryList = [...timeSheetEntryList];
    tmpTimeSheetEntryList[index]['disabled'] = false;
    setTimeSheetEntryList(tmpTimeSheetEntryList);
  };

  const handleCancelEditTimeSheet = (index) => {
    getTimeSheetEntry(index);
  };

  const handleSubmitTimeSheet = (index) => {
    updateTimeSheetEntry(index);
  };

  const handleStartTime = (index, update) => {
    console.log("Index: " + index);
    const tmpTimeSheetEntryList = [...timeSheetEntryList];
    tmpTimeSheetEntryList[index]['startTime'] = update;
    tmpTimeSheetEntryList[index]['noOfHours'] =  timeDiffInHours (update, tmpTimeSheetEntryList[index]['endTime']);

    setTimeSheetEntryList(tmpTimeSheetEntryList);

    console.log("Start Time: " + update);
    console.log("Start Time: " + (new Date(update)).toUTCString());
    console.log("Start Time: " + (new Date(update)).toISOString());
  };

  const handleEndTIme = (index, update) => {
    console.log("Index: " + index);
    const tmpTimeSheetEntryList = [...timeSheetEntryList];
    tmpTimeSheetEntryList[index]['endTime'] = update;
    tmpTimeSheetEntryList[index]['noOfHours'] =  timeDiffInHours (tmpTimeSheetEntryList[index]['startTime'], update);

    setTimeSheetEntryList(tmpTimeSheetEntryList);


    console.log("End time: " + update);
    console.log("End time: " + (new Date(update)).toISOString());
  };

  const handleHideGetWorkOrder = () => {
    getAssignedWorkOrderSchedule();
    setModalShow(false)
  };

  useEffect(() => {
    getAssignedWorkOrderSchedule();
    console.log("Selected id: " + selectedAssigneeId);
  }, [selectedAssigneeId]);

  function toLocaleDateString(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-SG');
  }

  function stringDateFormat(dateStr) {
    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-SG', options);
  }

  function stringTimeFormat(dateStr) {
    var date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-SG', {timeStyle: 'short', timeZone: 'Asia/Singapore', hour12: true}).format(date)
  }

  function timeDiffInHours(startTime, endTime){
    var diff = new Date(endTime).valueOf() -  new Date(startTime).valueOf();
    console.log("diff: " + diff);
    return diff/1000/60/60; // Convert milliseconds to hours
  }

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>Submit Time Sheet</h2>
          </div>
          <div className="card-body border-0">
            <div className="add-button">
              <>
                {/*<Button variant="btn btn-success" onClick={() => setModalShow(true)}>*/}
                {/*  Add adhoc time*/}
                {/*</Button>*/}

                <AssignWorkOrderDialog
                    show={modalShow}
                    onHide={() => handleHideGetWorkOrder()}
                    selectedAssigneeId={selectedAssigneeId}
                />
              </>
            </div>
          </div>
        </div>

        <table className="table table-bordered table-hover">
          <thead className="align-middle table-danger">
          <tr>
            <td>Client</td>
            <td>Project Name</td>
            <td>Purpose</td>
            <td>Type</td>
            <td>Scheduled Date</td>
            <td>Start Time</td>
            <td>Scheduled End Date</td>
            <td>End Time</td>
            <td>Hours</td>
            <td>ACTION</td>
          </tr>
          </thead>
          <tbody>
          {
            timeSheetEntryList &&
            timeSheetEntryList.map((workOrder, index) => (
                <tr key={index} className="table-dark">
                  <td>{workOrder.clientName}</td>
                  <td>{workOrder.clientProjectName}</td>
                  <td>{workOrder.projectSection}</td>
                  <td>{workOrder.assigneeType}</td>
                  <td>{stringDateFormat(workOrder.scheduleDate)}</td>
                  <td style={{width: '120px'}}>
                    <DatePicker
                        className="form-control"
                        selected={new Date(workOrder.startTime)}
                        onChange={(date) => handleStartTime(index, date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="hh:mm aa"
                        disabled={timeSheetEntryList[index]['disabled']}
                    />
                  </td>
                  <td>{stringDateFormat(workOrder.scheduleEndDate)}</td>
                  <td style={{width: '120px'}}>
                    <DatePicker
                        className="form-control e-small"
                        selected={new Date(workOrder.endTime)}
                        onChange={(date) => handleEndTIme(index, date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="hh:mm aa"
                        disabled={timeSheetEntryList[index]['disabled']}
                    />
                  </td>
                  <td>{workOrder.noOfHours}</td>
                  <td>
                    {(workOrder.submitStatus === 'SUBMITTED') && timeSheetEntryList[index]['disabled'] &&
                    <>
                      <button type="button" onClick={() => {
                        handleEditTimeSheet(index)
                      }}
                              className="btn btn-success btn-sm">Edit
                      </button>
                    </>
                    }
                    {(workOrder.submitStatus === 'NOT_SUBMITTED') &&
                    <>
                      <button type="button" onClick={() => {
                        handleSubmitTimeSheet(index)
                      }}
                              className="btn btn-warning btn-sm">Submit
                      </button>
                    </>
                    }
                    {(workOrder.submitStatus === 'SUBMITTED') && !timeSheetEntryList[index]['disabled'] &&
                    <>
                      <button type="button" onClick={() => {
                        handleCancelEditTimeSheet(index)
                      }}
                              className="btn btn-success btn-sm">Revert
                      </button>
                      &nbsp;
                      <button type="button" onClick={() => {
                        handleSubmitTimeSheet(index)
                      }}
                              className="btn btn-success btn-sm">Submit
                      </button>
                    </>
                    }
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </div>
  )
};

export default SubmitTimeSheet
