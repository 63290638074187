import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import api from "../../api/axiosConfig";
import {toast} from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import ViewWorkOrderQuoteDialog from "./dialog/ViewWorkOrderQuoteDialog";
import CreateViewWorkOrderQuoteDialog from "./dialog/CreateViewWorkOrderQuoteDialog";
import ViewWorkOrderScheduleDialog from "./dialog/schedule/ViewWorkOrderScheduleDialog";

const AllWorkOrder = (props) => {
  const navigate = useNavigate();

  const [selectedWorkOrderId, setSelectedWorkOrderId] = React.useState(null);
  const [createQuoteShow, setCreateQuoteShow] = React.useState(false);
  const [viewWorkOrderShow, setViewWorkOrderShow] = React.useState(false);
  const [viewWorkOrderScheduleShow, setViewWorkOrderScheduleShow] = React.useState(false);
  const [isWorkOrdersLoading, setIsWorkOrdersLoading] = React.useState(false);

  const [workOrderList, setWorkOrderList] = useState([]);

  const getClientWorkOrders = async () => {
    setIsWorkOrdersLoading(true);
    try {
      const response = await api.get(`/api/work-orders/all`);
      console.log(response.data);
      setWorkOrderList(response.data.content);
    } catch (err) {
      console.log(err);
    } finally {
      setIsWorkOrdersLoading(false);
    }
  };

  const updateClientWorkOrderStatus = async (workOrderId, submitStatus) => {
    try {
      const response = await api.put(`/api/work-orders/status/${workOrderId}`,
          {
            id: workOrderId,
            submitStatus: submitStatus
          }); // TODO try using body: JSON.Stringfy(data);
      toast.success("Work order schedule created successfully");
      getClientWorkOrders();
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditWorkOrderSchedule = (selectedAssigneeId) => {
    navigate(`/work-order/edit/${selectedAssigneeId}`);
  };

  const handleHideCreateQuote = () => {
    // setViewWorkOrderShow(false);
  };

  const handleHideViewWorkOrder = () => {
    setViewWorkOrderShow(false);
  };

  const handleHideCreateWorkOrder = () => {
    setCreateQuoteShow(false);
    getClientWorkOrders();
  };

  const handleHideViewWorkOrderSchedule = () => {
    setViewWorkOrderScheduleShow(false);
  };

  const handleViewWorkOrder = (workOrderId) => {
    setSelectedWorkOrderId(workOrderId);
    setViewWorkOrderShow(true);
  };

  const handleCreateWorkOrder = (workOrderId) => {
    setSelectedWorkOrderId(workOrderId);
    setCreateQuoteShow(true);
  };

  const handleCreateWorkOrderSchedule = (workOrderId) => {
    updateClientWorkOrderStatus(workOrderId, "PUBLISH");
  };

  // const handleUpdateWorkOrderSchedule = (workOrderId) => {
  //   // updateClientWorkOrderStatus(workOrderId, "PUBLISH");
  // };

  const handleViewWorkOrderSchedule = (workOrderId) => {
    setSelectedWorkOrderId(workOrderId);
    setViewWorkOrderScheduleShow(true);
  };

  // const handleRejectWorkOrder = (workOrderId) => {
  //   updateClientWorkOrderStatus(workOrderId, "QUOTE_REJECTED");
  // };

  const handleSubmitWorkOrderToClient = (workOrderId) => {
    updateClientWorkOrderStatus(workOrderId, "SUBMIT_TO_CLIENT");
  };

  useEffect(() => {
    getClientWorkOrders()
  }, []);

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>All Work Orders</h2>
          </div>
          <div className="card-body border-0">
            <div className="add-button">
              <>
                <ViewWorkOrderQuoteDialog
                    show={viewWorkOrderShow}
                    onHide={() => handleHideViewWorkOrder()}
                    selectedWorkOrderId={selectedWorkOrderId}
                />
              </>
              <>
                <CreateViewWorkOrderQuoteDialog
                    show={createQuoteShow}
                    onHide={() => handleHideCreateWorkOrder()}
                    selectedWorkOrderId={selectedWorkOrderId}
                />
              </>
              <>
                <ViewWorkOrderScheduleDialog
                    show={viewWorkOrderScheduleShow}
                    onHide={() => handleHideViewWorkOrderSchedule()}
                    selectedWorkOrderId={selectedWorkOrderId}
                />
              </>
            </div>
          </div>
        </div>

        <table className="table table-bordered">
          <thead className="bg-black text-white">
          <tr>
            <td>Client Name</td>
            <td>Work Order Reference</td>
            <td>Project Name</td>
            <td>Created Date</td>
            <td>Status</td>
            <td>ACTION</td>
          </tr>
          </thead>
          <tbody>
          {
            isWorkOrdersLoading ? (
                <tr>
                  <td rowSpan="1" colSpan="6">
                    <div className="text-center py-5">
                      <Spinner animation="border"/>
                    </div>
                  </td>
                </tr>
            ) : workOrderList && workOrderList.length > 0 ?
                (workOrderList.map((workOrder) => (
                    <tr key={workOrder.id}>
                      <td>{workOrder.clientName}</td>
                      <td>{workOrder.clientReference}</td>
                      <td>{workOrder.projectName}</td>
                      <td>{workOrder.createdDate}</td>
                      <td>{workOrder.status}</td>
                      <td>
                        <button type="button" onClick={() => {
                          handleViewWorkOrder(workOrder.id)
                        }}
                                className="btn btn-dark btn-sm">View
                        </button>
                        {(workOrder.status === 'PENDING_QUOTE') &&
                        <>
                          &nbsp;
                          <button type="button" onClick={() => {
                            handleCreateWorkOrder(workOrder.id)
                          }}
                                  className="btn btn-success btn-sm">Create Quote
                          </button>
                          {/*&nbsp;*/}
                          {/*<button type="button" onClick={() => {*/}
                          {/*  handleRejectWorkOrder(workOrder.id)*/}
                          {/*}}*/}
                          {/*        className="btn btn-success btn-sm">Reject*/}
                          {/*</button>*/}
                        </>
                        }
                        {(workOrder.status === 'PENDING_SUBMIT_TO_CLIENT') &&
                        <>
                          &nbsp;
                          <button type="button" onClick={() => {
                            handleCreateWorkOrder(workOrder.id)
                          }}
                                  className="btn btn-success btn-sm">Update Quote
                          </button>
                          &nbsp;
                          <button type="button" onClick={() => {
                            handleSubmitWorkOrderToClient(workOrder.id)
                          }}
                                  className="btn btn-success btn-sm">Submit To Client
                          </button>
                          {/*&nbsp;*/}
                          {/*<button type="button" onClick={() => {*/}
                          {/*  handleRejectWorkOrder(workOrder.id)*/}
                          {/*}}*/}
                          {/*        className="btn btn-danger btn-sm">Reject*/}
                          {/*</button>*/}
                        </>
                        }
                        {(workOrder.status === 'CLIENT_ACCEPTED') &&
                        <>
                          &nbsp;
                          <button type="button" onClick={() => {
                            handleCreateWorkOrderSchedule(workOrder.id)
                          }}
                                  className="btn btn-success btn-sm">Create Schedule
                          </button>
                        </>
                        }
                        {(workOrder.status === 'PENDING_ASSIGN') &&
                        <>
                          &nbsp;
                          <button type="button" onClick={() => {
                            handleEditWorkOrderSchedule(workOrder.id)
                          }}
                                  className="btn btn-success btn-sm">Update Schedule
                          </button>
                          &nbsp;
                          <button type="button" onClick={() => {
                            handleViewWorkOrderSchedule(workOrder.id)
                          }}
                                  className="btn btn-success btn-sm">View Schedule
                          </button>
                        </>
                        }

                      </td>
                    </tr>
                ))) : (
                    <tr>
                      <td colSpan="6">
                        <h5 className="text-danger text-center">No work orders found</h5>
                      </td>
                    </tr>
                )
          }
          </tbody>
        </table>
      </div>
  )
};

export default AllWorkOrder
