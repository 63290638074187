import React from 'react'
import './FooterStyles.css';
import {version} from '../../api/AppConfig'

const Footer = () => {

  return (
      <div>
        <footer className = "footer">
          <span className="text-light">Copyright © 2024 MDSS Pte. Ltd. - Version {version}</span>
        </footer>
      </div>
  )
}
export default Footer
