import React from 'react'
import Modal from 'react-bootstrap/Modal';
import ViewWorkOrderSchedule from "./ViewWorkOrderSchedule";
import EditWorkOrderScheduleTime from "./EditWorkOrderScheduleTime";

const EditWorkOrderScheduleTimeDialog = (props) => {
  return (
      <Modal
          {...props}
          onHide={() => props.onHide()}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          backdrop="static"
          scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Break schedule time
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <EditWorkOrderScheduleTime
                onHide={props.onHide}
                selectedWorkOrderScheduleSubId={props.selectedWorkOrderScheduleSubId}
            />
          }
        </Modal.Body>
        {/*<Modal.Footer>*/}
        {/*  <button variant="secondary" onClick={props.onHide}>*/}
        {/*    Close*/}
        {/*  </button>*/}
        {/*  <button variant="primary">Understood</button>*/}
        {/*</Modal.Footer>*/}
      </Modal>
  )
};

export default EditWorkOrderScheduleTimeDialog
