import React, {useEffect, useState} from 'react'
import api from "../../../../api/axiosConfig";
import {useNavigate, useParams} from "react-router";
import EditWorkOrderScheduleTimeDialog from "./EditWorkOrderScheduleTimeDialog";
import {toast} from "react-toastify";
import Button from "react-bootstrap/Button";

const EditWorkOrderSchedule = (props) => {
	const navigate = useNavigate();

	// const selectedWorkOrderId = props.selectedWorkOrderId;
	let params = useParams();
	const selectedWorkOrderId = params.selectedWorkOrderId;

	const initialClientState = {
		id: "",
		clientId: "",
		clientName: "",
		clientProjectId: "",
		clientReference: "",
		createdDate: "",
		projectAddress: "",
		projectCode: "",
		projectName: "",
		status: "",
		scheduleType: ""
	};

	const [workOrder, setWorkOrder] = useState(initialClientState);
	const [workOrderItemList, setWorkOrderItemList] = useState([]);
	const [viewWorkOrderScheduleShow, setViewWorkOrderScheduleShow] = React.useState(false);
	const [selectedWorkOrderScheduleSubId, setSelectedWorkOrderScheduleSubId] = React.useState(null);

	const getWorkOrder = async () => {
		try {
			const response = await api.get(`/api/work-orders/${selectedWorkOrderId}`);
			console.log(response.data);
			setWorkOrder(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	const getClientWorkOrderItems = async () => {
		try {
			const response = await api.get(`/api/work-order-schedule-sub/work-order?clientWorkOrderId=${selectedWorkOrderId}`);
			console.log(response.data);
			setWorkOrderItemList(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	const deleteWorkOrderScheduleSplits = async (workOrderScheduleSubId) => {
		try {
			const response = await api.delete(`/api/work-order-schedule-sub/split/${workOrderScheduleSubId}`);
			console.log(response.data);
			getClientWorkOrderItems();
		} catch (err) {
			console.log(err);
		}
	};

	const cancelWorkOrderSchedule = async (workOrderScheduleSubId) => {
		try {
			const response = await api.put(`/api/work-order-schedule-sub/cancel/${workOrderScheduleSubId}`,
				{}); // TODO try using body: JSON.Stringfy(data);
			toast.success("Work order schedule cancelled successfully");
			console.log(response.data);
			getClientWorkOrderItems();
		} catch (err) {
			console.log(err);
		}
	};

	const backToAllWorkOrders = () => {
		navigate(`/work-order`);
	};

	const handleViewWorkOrder = (workOrderItemId) => {
		setSelectedWorkOrderScheduleSubId(workOrderItemId);
		setViewWorkOrderScheduleShow(true);
	};

	const handleHideViewWorkOrderSchedule = () => {
		setViewWorkOrderScheduleShow(false);
		getClientWorkOrderItems();
	};

	const handleDeleteSplits = (workOrderScheduleSubId) => {
		deleteWorkOrderScheduleSplits(workOrderScheduleSubId);
	};

	const handleCancelSchedule = (workOrderScheduleSubId) => {
		cancelWorkOrderSchedule(workOrderScheduleSubId);
	};

	const handleCancel = (e) => {
		e.preventDefault();
	};

	useEffect(() => {
		getWorkOrder();
		getClientWorkOrderItems();
		console.log("Selected id: " + selectedWorkOrderId);
	}, []);

	function stringDateFormat(dateStr) {
		const options = {month: 'long', day: 'numeric', year: 'numeric'};
		const date = new Date(dateStr);
		return date.toLocaleDateString('en-SG', options);
	}

	function stringTimeFormat(dateStr) {
		var date = new Date(dateStr);
		return new Intl.DateTimeFormat('en-SG', {timeStyle: 'short', timeZone: 'Asia/Singapore', hour12: true}).format(date)
	}

	return (
		<div className="container">
			<>
				<EditWorkOrderScheduleTimeDialog
					show={viewWorkOrderScheduleShow}
					onHide={() => handleHideViewWorkOrderSchedule()}
					selectedWorkOrderScheduleSubId={selectedWorkOrderScheduleSubId}
				/>
			</>
			<div className="row">
				<div className="offset-lg-0 col-lg-12">
					<div className="card border-0">
						<div className="card-header border-0 center-content">
							<h4>Update Work Order Schedule</h4>
						</div>
						<div className="card-body">
							<div className="row">
								<div className="col-sm-2">
									<button type="button" onClick={() => {
										backToAllWorkOrders()
									}}
									        className="btn btn-success">
										Back
									</button>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-2">
									<h6>&nbsp;</h6>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-2">
									<h6>Client Reference</h6>
								</div>
								<div className="col-sm-4">
									<h6>{workOrder.clientReference}</h6>
								</div>
								<div className="col-sm-2">
									<h6>Project</h6>
								</div>
								<div className="col-sm-4">
									<h6>{workOrder.projectName}</h6>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-2">
									<h6>Client</h6>
								</div>
								<div className="col-sm-4">
									<h6>{workOrder.clientName}</h6>
								</div>
								<div className="col-sm-2">
									<h6>Project Address</h6>
								</div>
								<div className="col-sm-4">
									<h6>{workOrder.projectAddress}</h6>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-2">
									<h6>&nbsp;</h6>
								</div>
							</div>
							<div>
								<table className="table table-bordered table-hover">
									<thead className="align-middle table-danger">
									<tr>
										<td>Purpose</td>
										<td>Assignee Type</td>
										<td>Scheduled Date</td>
										<td>Scheduled End Date</td>
										<td>Time slot</td>
										<td>Assign Status</td>
										<td>Action</td>
									</tr>
									</thead>
									<tbody>
									{
										workOrderItemList &&
										workOrderItemList.map((workOrderItem) => (
											<tr key={workOrderItem.id}
											    className={workOrderItem.scheduleType === 'PRIMARY' ? (workOrderItem.status === 'SPLITTED' ? 'table-primary' : 'table-dark') : 'table-warning'}>
												<td>{workOrderItem.projectSection}</td>
												<td>{workOrderItem.assigneeType}</td>
												<td>{stringDateFormat(workOrderItem.scheduleDate)}</td>
												<td>{stringDateFormat(workOrderItem.scheduleEndDate)}</td>
												<td>{stringTimeFormat(workOrderItem.startTime) + " - " + stringTimeFormat(workOrderItem.endTime)}</td>
												<td>{workOrderItem.status}</td>
												<td>
													{(workOrderItem.status !== 'SPLITTED' && workOrderItem.status !== 'CANCELLED') &&
													<>
														<button type="button" onClick={() => {
															handleCancelSchedule(workOrderItem.id)
														}}
														        className="btn btn-success btn-sm">Cancel
														</button>
														&nbsp;
														&nbsp;
													</>
													}
													{(workOrderItem.status === 'NOT_ASSIGNED' && workOrderItem.scheduleType === 'PRIMARY') &&
													<>
														<button type="button" onClick={() => {
															handleViewWorkOrder(workOrderItem.id)
														}}
														        className="btn btn-warning btn-sm">Break
														</button>
													</>
													}
													{(workOrderItem.status === 'SPLITTED' && workOrderItem.scheduleType === 'PRIMARY') &&
													<>
														<button type="button" onClick={() => {
															handleDeleteSplits(workOrderItem.id)
														}}
														        className="btn btn-danger btn-sm">Delete split
														</button>
													</>
													}
												</td>
											</tr>
										))
									}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};

export default EditWorkOrderSchedule
