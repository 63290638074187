import React, {useEffect, useState} from 'react'
import api from "../../../../api/axiosConfig";
import DatePicker from "react-datepicker";
import {BiError} from "react-icons/bi"
import {toast} from "react-toastify";

const EditWorkOrderScheduleTime = (props) => {
	const selectedWorkOrderScheduleSubId = props.selectedWorkOrderScheduleSubId;

	const initialWorkOrderScheduleSub = {
		id: "",
		clientId: "",
		clientName: "",
		clientWorkOrderId: "",
		clientWorkOrderItemId: "",
		assigneeTypeId: "",
		assigneeType: "",
		status: "",
		assigneeId: "",
		clientProjectId: "",
		clientProjectName: "",
		clientProjectSectionId: "",
		projectSection: "",
		scheduleType: "",
		scheduleDate: "",
		scheduleEndDate: "",
		startTime: "",
		endTime: "",
	};

	const [workOrderScheduleSub, setWorkOrderScheduleSub] = useState(initialWorkOrderScheduleSub);
	const [originalTotalHours, setOriginalTotalHours] = useState(0);
	const [newTotalHours, setNewTotalHours] = useState(0);
	const [rowsData, setRowsData] = useState([]);
	const [newTotalHourStatus, setNewTotalHourStatus] = useState(0);

	const getWorkOrderScheduleSub = async () => {
		try {
			const response = await api.get(`/api/work-order-schedule-sub/${selectedWorkOrderScheduleSubId}`);
			console.log(response.data);
			setWorkOrderScheduleSub(response.data);

			// let originalTotHours = getTotalHours(toCompleteDateFromString(response.data.scheduleDate, response.data.startTime), toCompleteDateFromString(response.data.scheduleEndDate, response.data.endTime));
			let originalTotHours = getTotalHours(new Date(response.data.startTime), new Date(response.data.endTime));
			setOriginalTotalHours(originalTotHours);
			console.log("Total Hours: " + originalTotHours);

			const rowsInput = {
				id: '',
				requestStartDate: (new Date(response.data.startTime)).toISOString(),
				startTime: (new Date(response.data.startTime)).toISOString(),
				requestEndDate: (new Date(response.data.endTime)).toISOString(),
				endTime: (new Date(response.data.endTime)).toISOString(),
				hasTimeRangeError: false
			};
			const rows = [rowsInput];
			validateDates(rows, originalTotHours);
			setRowsData(rows)
		} catch (err) {
			console.log(err);
		}
	};

	const saveWorkOrderScheduleSplit = async () => {
		try {
			const response = await api.post("/api/work-order-schedule-sub/split",
				{
					id: selectedWorkOrderScheduleSubId,
					workOrderScheduleSliceItemRequestList: rowsData
				}); // TODO try using body: JSON.Stringfy(data);

			console.log("Sending date objects >>>>>>>>>> \n" + rowsData);
			for (let i = 0; i < rowsData.length; i++) {
				const x = rowsData[i];
				console.log("Start: " + x.startTime + "  End: " + x.endTime)
			}
			toast.success("Work order split time saved successfully");
			console.log("<<<<<<<<<" + response.data);
			props.onHide();
		} catch (err) {
			toast.error(err.response.data.errorDetails[0].message);
			console.log(err);
		}
	};

	const addTableRows = () => {
		const rowsInput = {
			id: '',
			requestStartDate: (new Date(workOrderScheduleSub.startTime)).toISOString(),
			startTime: (new Date(workOrderScheduleSub.startTime)).toISOString(),
			requestEndDate: (new Date(workOrderScheduleSub.endTime)).toISOString(),
			endTime: (new Date(workOrderScheduleSub.endTime)).toISOString(),
			hasTimeRangeError: false
		};
		const rows = [...rowsData];
		rows.push(rowsInput);
		if (getTimeDiffHours((rows.length - 1), rows) <= 0) {
			rows[rows.length - 1]['hasTimeRangeError'] = true;
		} else {
			rows[rows.length - 1]['hasTimeRangeError'] = false;
		}
		validateDates(rows, originalTotalHours);
		setRowsData(rows);
		console.log("New rows data length 1: " + rowsData.length);
	};

	const deleteTableRows = (index) => {
		console.log("Removing index: " + index);
		const rows = [...rowsData];
		let deletedData = rows.splice(index, 1);
		console.log("Deleted data length 2: " + deletedData.length);
		console.log("New rows data length 2: " + rows.length);
		validateDates(rows, originalTotalHours);
		setRowsData(rows);
	};

	function stringDateFormat(dateStr) {
		if (dateStr === null || dateStr === undefined || dateStr.length === 0) {
			return "";
		}
		const options = {month: 'long', day: 'numeric', year: 'numeric'};
		const date = new Date(dateStr);
		return date.toLocaleDateString('en-SG', options);
	}

	function stringTimeFormat(dateStr) {
		if (dateStr === null || dateStr === undefined || dateStr.length === 0) {
			return "";
		}
		var date = new Date(dateStr);
		return new Intl.DateTimeFormat('en-SG', {timeStyle: 'short', timeZone: 'Asia/Singapore', hour12: true}).format(date)
	}

	function toCompleteDateFromString(dateStr, timeStr) {
		if (dateStr === null || dateStr === undefined || dateStr.length === 0) {
			return "";
		}

		if (timeStr === null || timeStr === undefined || timeStr.length === 0) {
			return "";
		}

		const date = new Date(dateStr);
		const time = new Date(timeStr);
		return toCompleteDate(date, time);
	}

	function toCompleteDate(date, time) {
		var year = date.getFullYear();
		var month = date.getMonth() + 1; // Jan is 0, dec is 11
		var day = date.getDate();
		var dateString = '' + year + '-' + month + '-' + day;

		var timeString = time.getHours() + ':' + time.getMinutes() + ':00';

		return new Date(dateString + ' ' + timeString);
	}

	function getTotalHours(statTime, endTime) {
		return (endTime - statTime) / 36e5;
	}

	function getCombinedDateTime(date, time){
		var dateObject = new Date(date);
		var timeObject = new Date(time);
		return new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(),
			timeObject.getHours(), timeObject.getMinutes(), 0);
	}

	const handleStartDate = (index, update) => {
		console.log("Index: " + index);
		const rows = [...rowsData];
		rows[index]['requestStartDate'] = (new Date(update)).toISOString();
		rows[index]['startTime'] = (getCombinedDateTime(rows[index]['requestStartDate'], rows[index]['startTime']).toISOString());
		if (getTimeDiffHours(index, rows) <= 0) {
			rows[index]['hasTimeRangeError'] = true;
		} else {
			rows[index]['hasTimeRangeError'] = false;
		}
		validateDates(rows, originalTotalHours);
		setRowsData(rows);

		console.log(">> requestStartDate: " + update);
		console.log(">> requestStartDate: " + (new Date(update)).toISOString());
	};

	const handleEndDate = (index, update) => {
		console.log("Index: " + index);
		const rows = [...rowsData];
		rows[index]['requestEndDate'] = (new Date(update)).toISOString();
		rows[index]['endTime'] = (getCombinedDateTime(rows[index]['requestEndDate'], rows[index]['endTime']).toISOString());

		if (getTimeDiffHours(index, rows) <= 0) {
			rows[index]['hasTimeRangeError'] = true;
		} else {
			rows[index]['hasTimeRangeError'] = false;
		}
		validateDates(rows, originalTotalHours);
		setRowsData(rows);

		console.log(">> requestEndDate: " + update);
		console.log(">> requestEndDate: " + (new Date(update)).toISOString());
	};

	const handleStartTime = (index, update) => {
		console.log("Index: " + index);
		const rows = [...rowsData];
		rows[index]['startTime'] = (new Date(update)).toISOString();
		if (getTimeDiffHours(index, rows) <= 0) {
			rows[index]['hasTimeRangeError'] = true;
		} else {
			rows[index]['hasTimeRangeError'] = false;
		}
		validateDates(rows, originalTotalHours);
		setRowsData(rows);

		console.log(">> startTime: " + update);
		console.log(">> startTime: " + (new Date(update)).toISOString());
	};

	const handleEndTIme = (index, update) => {
		console.log("Index: " + index);
		const rows = [...rowsData];
		rows[index]['endTime'] = (new Date(update)).toISOString();
		if (getTimeDiffHours(index, rows) <= 0) {
			rows[index]['hasTimeRangeError'] = true;
		} else {
			rows[index]['hasTimeRangeError'] = false;
		}
		validateDates(rows, originalTotalHours);
		setRowsData(rows);

		console.log(">> endTime: " + update);
		console.log(">> endTime: " + (new Date(update)).toISOString());
	};

	const getTimeDiffHours = (index, brokenDownTimeArray) => {
		const x = brokenDownTimeArray[index];
		if (x.requestStartDate === undefined || x.startTime === undefined || x.requestEndDate === undefined || x.endTime === undefined) {
			return 0;
		}

		if (index > 0) {
			const y = brokenDownTimeArray[index - 1];
			if (y.requestStartDate === undefined || y.startTime === undefined || y.requestEndDate === undefined || y.endTime === undefined) {
				return 0;
			}

			let preRowHours = getTotalHours(toCompleteDateFromString(y.requestEndDate, y.endTime), toCompleteDateFromString(x.requestStartDate, x.startTime));
			console.log("preRowHours >>>>>>>>>>>>> : " + preRowHours);
			if (preRowHours < 0) {
				return preRowHours;
			}
			return getTotalHours(toCompleteDateFromString(x.requestStartDate, x.startTime), toCompleteDateFromString(x.requestEndDate, x.endTime));
		} else {
			return getTotalHours(toCompleteDateFromString(x.requestStartDate, x.startTime), toCompleteDateFromString(x.requestEndDate, x.endTime));
		}

	};

	const validateDates = (brokenDownTimeArray, originalTotHours) => {
		console.log("Validating dates " + brokenDownTimeArray.length);
		const f = brokenDownTimeArray.entries();
		var totalHours = 0;

		for (let i = 0; i < brokenDownTimeArray.length; i++) {
			const x = brokenDownTimeArray[i];
			if (x.requestStartDate === undefined || x.startTime === undefined || x.requestEndDate === undefined || x.endTime === undefined) {
				return;
			}
			totalHours += getTotalHours(toCompleteDateFromString(x.requestStartDate, x.startTime), toCompleteDateFromString(x.requestEndDate, x.endTime));
		}
		setNewTotalHours(totalHours);

		if (totalHours > originalTotHours) {
			console.log("All entries total hours: " + totalHours + " Original total hours: " + originalTotHours);
			setNewTotalHourStatus(1);
		} else if (totalHours < originalTotHours) {
			setNewTotalHourStatus(-1);
		} else {
			setNewTotalHourStatus(0);
		}
		console.log("All entries total hours: " + totalHours);
	};

	const handleWorkOrderScheduleSplit = (e) => {
		e.preventDefault();
		saveWorkOrderScheduleSplit();
	};

	const handleCancel = (e) => {
		e.preventDefault();
		props.onHide();
	};

	useEffect(() => {
		getWorkOrderScheduleSub();
		console.log("Selected id: " + selectedWorkOrderScheduleSubId);
	}, []);

	return (
		<div>
			<form className="container">
				<div className="row">
					<div className="offset-lg-0 col-lg-12">
						<div className="card border-0">
							<div style={{height: 'calc(70vh - 250px)'}}>
								<div className="row bg-body-secondary">
									<div className="col-sm-3">
										<h5>Start date</h5>
									</div>
									<div className="col-sm-2">
										<h5>Start time</h5>
									</div>
									<div className="col-sm-3">
										<h5>End date</h5>
									</div>
									<div className="col-sm-2">
										<h5>End time</h5>
									</div>
									<div className="col-sm-2">
										<h5>Action</h5>
									</div>

								</div>
								<div className="row">
									<div className="col-sm-3">
										<h6>{stringDateFormat(workOrderScheduleSub.scheduleDate)}</h6>
									</div>
									<div className="col-sm-2">
										<h6>{stringTimeFormat(workOrderScheduleSub.startTime)}</h6>
									</div>
									<div className="col-sm-3">
										<h6>{stringDateFormat(workOrderScheduleSub.scheduleEndDate)}</h6>
									</div>
									<div className="col-sm-2">
										<h6>{stringTimeFormat(workOrderScheduleSub.endTime)}</h6>
									</div>
									<div className="col-sm-2 bg">
										{(rowsData.length === 0) &&
										<button type="button" onClick={addTableRows}
										        className="btn btn-outline-success btn-sm">+
										</button>
										}
									</div>
								</div>
								<hr/>
								<div>
									{
										rowsData &&
										rowsData.map((data, index) => (
											<>
												<div className="row">
													<div className="col-sm-3">
														<DatePicker
															className={newTotalHourStatus === 0 ? 'form-control' : newTotalHourStatus === -1 ? 'form-control bg-info' : 'form-control bg-danger'}
															selectsRange={false}
															selected={new Date(data.requestStartDate)}
															onChange={(update) => {
																handleStartDate(index, update)
															}}
															dateFormat="d MMMM yyyy"
															selectsDisabledDaysInRange={false}
															style={{width: '100%'}}
														/>
													</div>
													<div className="col-sm-2">
														<DatePicker
															className={newTotalHourStatus === 0 ? 'form-control' : newTotalHourStatus === -1 ? 'form-control bg-info' : 'form-control bg-danger'}
															selected={new Date(data.startTime)}
															onChange={(date) => handleStartTime(index, date)}
															showTimeSelect
															showTimeSelectOnly
															timeIntervals={30}
															timeCaption="Time"
															dateFormat="h:mm aa"
														/>
													</div>
													<div className="col-sm-3">
														<DatePicker
															className={newTotalHourStatus === 0 ? 'form-control' : newTotalHourStatus === -1 ? 'form-control bg-info' : 'form-control bg-danger'}
															selectsRange={false}
															selected={new Date(data.requestEndDate)}
															onChange={(update) => {
																handleEndDate(index, update)
															}}
															dateFormat="d MMMM yyyy"
															selectsDisabledDaysInRange={false}
															style={{width: '100%'}}
														/>
													</div>
													<div className="col-sm-2">
														<DatePicker
															className={newTotalHourStatus === 0 ? 'form-control' : newTotalHourStatus === -1 ? 'form-control bg-info' : 'form-control bg-danger'}
															selected={new Date(data.endTime)}
															onChange={(date) => handleEndTIme(index, date)}
															showTimeSelect
															showTimeSelectOnly
															timeIntervals={30}
															timeCaption="Time"
															dateFormat="h:mm aa"
														/>
													</div>
													<div className="col-sm-2">
														<button type="button" onClick={() => (deleteTableRows(index))}
														        className="btn btn btn-outline-danger btn-sm">x
														</button>
														&nbsp;
														&nbsp;
														{(rowsData.length === (index + 1) && rowsData.length < 6) &&
														<button type="button" onClick={addTableRows}
														        className="btn btn-outline-success btn-sm" autoFocus>+
														</button>
														}
														&nbsp;
														{data.hasTimeRangeError &&
														<BiError style={{color: 'red', fontSize: '30px'}}/>
														}
													</div>
												</div>
											</>
										))
									}
								</div>
							</div>
							<div className="row">
								<h6>{newTotalHourStatus > 0 ? 'New total hours: ' + newTotalHours + ' greater than original total hours: ' + originalTotalHours :
									newTotalHourStatus < 0 ? 'New total hours: ' + newTotalHours + ' less than original total hours: ' + originalTotalHours :
										''}
								</h6>
							</div>
							<div className="card-footer border-0 center-content">
								<button type="button" onClick={handleWorkOrderScheduleSplit}
								        className="btn btn-success btn-sm">Create
								</button>
								&nbsp;
								<button type="button" onClick={handleCancel}
								        className="btn btn-success btn-sm">Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
};

export default EditWorkOrderScheduleTime
