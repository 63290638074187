import React from 'react'

const Home = () => {

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>DASHBOARD</h2>
          </div>
          <div className="card-body border-0">
            <div className="add-button">
              {/*<>*/}
              {/*  <Button variant="btn btn-success" onClick={() => setAddModalShow(true)}>*/}
              {/*    Create Work Order*/}
              {/*  </Button>*/}

              {/*  <CreateWorkOrderDialog*/}
              {/*      show={addModalShow}*/}
              {/*      onHide={() => handleHideAddModal()}*/}
              {/*      selectedClientId={selectedClientId}*/}
              {/*  />*/}
              {/*</>*/}
            </div>
          </div>
        </div>
      </div>
  )
}

export default Home
