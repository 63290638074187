import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import api from "../../api/axiosConfig";
import {toast} from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

const VerifyExtEmail = () => {
	let params = useParams();
	const userId = params.userId;
	const verificationCode = params.verificationCode;

	const [isWorkOrdersLoading, setIsWorkOrdersLoading] = React.useState(false);
	const [displayMessage, setDisplayMessage] = React.useState('Your email has been successfully verified');

	const handlesubmit = async () => {
		try {
			const response = await api.get(`/pub/api/verify-ext-email/${userId}/${verificationCode}`);
			console.log(response.data);
		} catch (err) {
			toast.error(err.response.data.errorDetails[0].message);
			console.log(err);
			setDisplayMessage("Email verification failed. Please try again.")
		}finally {
			setIsWorkOrdersLoading(false)
		}
	};

	useEffect(() => {
		setIsWorkOrdersLoading(true)
		handlesubmit()
	}, []);

	return (
		<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh'}}>
			<form className="container">
				<div className="row">
					<div className="offset-lg-3 col-lg-6">
						{
							isWorkOrdersLoading ? (
								<div className="text-center py-5">
									<Spinner animation="border"/>
								</div>
							) : (
								<div className="text-center py-5">
									<h1>{displayMessage}</h1>
								</div>
							)
						}
					</div>
				</div>
			</form>
		</div>
	)
};

export default VerifyExtEmail
