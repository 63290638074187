import React, {useState} from 'react'
import DatePicker from "react-datepicker";
import {Col, Container, Row} from "react-bootstrap";
import DeleteProjectDialog from "../project/DeleteProjectDialog";
import DeleteWorkOrderItemDialog from "./DeleteWorkOrderItemDialog";
import api from "../../../../api/axiosConfig";

const EditWorkOrderItem = (props) => {

  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const isWeekday = (date, periodType) => {
    const day = date.getDay();
    if(periodType === 'WEEKDAYS_ONLY'){
      return day !== 0 && day !== 6;
    }else if(periodType === 'WEEKDAYS_WITH_SATURDAYS'){
      return day !== 0;
    }else if(periodType === 'WEEKDAYS_WITH_SUNDAYS'){
      return day !== 6;
    }else if(periodType === 'WEEKENDS_ONLY'){
      return day === 0 || day === 6;
    }else if(periodType === 'SATURDAYS_ONLY'){
      return day === 6;
    }else if(periodType === 'SUNDAYS_ONLY'){
      return day === 0;
    }
    return true;
  };

  const addTableRows = (e) => {
    const rowsInput = {
      id: '',
      clientProjectSectionId: '',
      assigneeTypeId: '',
      periodType: 'SINGLE_DATE',
      requestStartDate: '',
      requestEndDate: '',
      startTime: '',
      endTime: ''
    };
    props.setRowsData(oldArray => [...oldArray, rowsInput]);
  };

  const deleteWorkOrderItemRemoteAndLocal = async () => {
    const rowsInput = [...props.rowsData];
    console.log("Row index >>>>>>>>>>  " + rowsInput[selectedIndex]['id']);
    try {
      const response = await api.delete(`/api/work-order-items/${rowsInput[selectedIndex]['id']}`);
      console.log(response.data);

      const rows = [...props.rowsData];
      rows.splice(selectedIndex, 1);
      props.setRowsData(rows);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteWorkOrderItemLocal = () => {
    const rows = [...props.rowsData];
    rows.splice(selectedIndex, 1);
    props.setRowsData(rows);
  };

  const deleteWorkOrderItem = () => {
    const rowsInput = [...props.rowsData];
    if(rowsInput[selectedIndex]['id'] === undefined || rowsInput[selectedIndex]['id'] === null || rowsInput[selectedIndex]['id'].trim().length === 0){
      deleteWorkOrderItemLocal();
    }else{
      deleteWorkOrderItemRemoteAndLocal();
    }
  };

  const deleteTableRows = (index) => {
    setSelectedIndex(index);
    setDeleteModalShow(true);
  };

  const handleHideDeleteModal = () => {
    // getClientProjects();
    setDeleteModalShow(false);
  };

  const handleChange = (index, evnt) => {
    evnt.preventDefault();
    const {name, value} = evnt.target;
    const rowsInput = [...props.rowsData];
    rowsInput[index][name] = value;
    if (name === 'periodType') {
      rowsInput[index]['requestStartDate'] = null;
      rowsInput[index]['requestEndDate'] = null;
    }
    props.setRowsData(rowsInput);
  };

  const handleSingleDate = (index, update) => {
    console.log("Index: " + index);
    const rowsInput = [...props.rowsData];
    rowsInput[index]['requestStartDate'] = update;
    rowsInput[index]['requestEndDate'] = null;
    props.setRowsData(rowsInput);
  };

  const handleDateRange = (index, update) => {
    console.log("Index: " + index);
    const [startDate1, endDate1] = update;
    const rowsInput = [...props.rowsData];
    rowsInput[index]['requestStartDate'] = new Date(startDate1).toISOString();
    rowsInput[index]['requestEndDate'] = new Date(endDate1).toISOString();
    props.setRowsData(rowsInput);

    console.log("Start date: " + startDate1);
    console.log("Start date: " + (new Date(startDate1)).toUTCString());
    console.log("Start date: " + (new Date(startDate1)).toISOString());
    console.log("End date: " + endDate1);
    console.log("End date: " + (new Date(endDate1)).toUTCString());
    console.log("End date: " + (new Date(endDate1)).toISOString());
  };

  const handleStartTime = (index, update) => {
    console.log("Index: " + index);
    const rowsInput = [...props.rowsData];
    rowsInput[index]['startTime'] = new Date(update).toISOString();
    props.setRowsData(rowsInput);

    console.log("Start Time: " + update);
    console.log("Start Time: " + (new Date(update)).toUTCString());
    console.log("Start Time: " + (new Date(update)).toISOString());
  };

  const handleEndTIme = (index, update) => {
    console.log("Index: " + index);
    const rowsInput = [...props.rowsData];
    rowsInput[index]['endTime'] = new Date(update).toISOString();
    props.setRowsData(rowsInput);

    console.log("End time: " + update);
    console.log("End time: " + (new Date(update)).toISOString());
  };

  return (
      <Container>
        <DeleteWorkOrderItemDialog
            show={deleteModalShow}
            onHide={() => handleHideDeleteModal()}
            deleteproject={() => deleteWorkOrderItem()}
        />
        <Col>
          <Row className="mt-2">
            {(props.rowsData.length === 0) &&
            <button type="button" onClick={addTableRows}
                    className="btn btn-success">Add
            </button>
            }
          </Row>

          {
            props.rowsData &&
            props.rowsData.map((data, index) => (
                <>
                  <Row className="mt-2">
                    <hr/>
                  </Row>
                  <Row md={2}>
                    <Col className="border-2">
                      <Row>
                        <label>Project Section</label>
                      </Row>
                      <Row>
                        <select className="form-control " id="clientProjectId" value={data.clientProjectSectionId}
                                onChange={(evnt) => (handleChange(index, evnt))} name="clientProjectSectionId">
                          <option key="000000" value=""></option>
                          {props.projectSectionList &&
                          props.projectSectionList.map((projectSection) => (
                              <option key={projectSection.id} value={projectSection.id}>{projectSection.sectionName}</option>
                          ))
                          }
                        </select>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <label>Assgnee type</label>
                      </Row>
                      <Row>
                        <select className="form-control" id="assigneeTypeId" value={data.assigneeTypeId}
                                onChange={(evnt) => (handleChange(index, evnt))} name="assigneeTypeId">
                          <option value=""></option>
                          {props.assigneeTypeList &&
                          props.assigneeTypeList.map((assigneeType) => (
                              <option key={assigneeType.id} value={assigneeType.id}>{assigneeType.type}</option>
                          ))
                          }
                        </select>
                      </Row>
                    </Col>
                    <Col>
                    </Col>
                  </Row>

                  <Row md={10}>
                    <Col md={2}>
                      <Row>
                        <label>Period type</label>
                      </Row>
                      <Row>
                        <select className="form-control" id="periodType" value={data.periodType}
                                onChange={(evnt) => (handleChange(index, evnt))} name="periodType">
                          {props.periodTypeList &&
                          props.periodTypeList.map((periodType) => (
                              <option key={periodType.periodType} value={periodType.periodType}>{periodType.periodType}</option>
                          ))
                          }
                        </select>
                      </Row>
                    </Col>
                    <Col md={4} className="customDatePickerWidth">
                      <Row>
                        <label>Period</label>
                      </Row>
                      <Row>
                        <DatePicker
                            className="form-control"
                            filterDate={(date) => (isWeekday(date, data.periodType))}
                            selectsRange={data.periodType !== 'SINGLE_DATE'}
                            selected={Date.parse(data.requestStartDate)}
                            startDate={Date.parse(data.requestStartDate)}
                            endDate={Date.parse(data.requestEndDate)}
                            onChange={(update) => {
                              if (data.periodType !== 'SINGLE_DATE'){
                                handleDateRange(index, update);
                              }else {
                                handleSingleDate(index, update)
                              }
                            }}
                            dateFormat="d MMMM yyyy"
                            selectsDisabledDaysInRange={false}
                            style={{width: '100%'}}
                        />
                      </Row>
                    </Col>
                    <Col md={2}>
                      <Row>
                        <label>Start time</label>
                      </Row>
                      <Row>
                        <DatePicker
                            className="form-control"
                            selected={Date.parse(data.startTime)}
                            onChange={(date) => handleStartTime(index, date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                        />
                      </Row>
                    </Col>
                    <Col md={2}>
                      <Row>
                        <label>End time</label>
                      </Row>
                      <Row>
                        <DatePicker
                            className="form-control e-small"
                            selected={Date.parse(data.endTime)}
                            onChange={(date) => handleEndTIme(index, date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                        />
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <label>&nbsp;</label>
                      </Row>
                      <Row>
                        <button type="button" onClick={() => (deleteTableRows(index))}
                                className="btn btn btn-danger">Delete
                        </button>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <label> &nbsp;</label>
                      </Row>
                      <Row>
                        {(props.rowsData.length === (index + 1)) &&
                        <button type="button" onClick={addTableRows}
                                className="btn btn-success" autoFocus>Add
                        </button>
                        }
                      </Row>
                    </Col>
                  </Row>
                </>
            ))
          }
        </Col>
      </Container>
  )
};

export default EditWorkOrderItem
