import React from 'react'
import Modal from 'react-bootstrap/Modal';
import EditProject from "./EditProject";

const EditProjectDialog = (props) => {
  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditProject
              onHide={props.onHide}
              selectedProjectId={props.selectedProjectId}
          />
        </Modal.Body>
      </Modal>
  )
};

export default EditProjectDialog
