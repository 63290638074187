import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";
import Button from "react-bootstrap/Button";
import CreateProjectDialog from "./dialog/project/CreateProjectDialog";
import api from "../../api/axiosConfig";
import EditProjectDialog from "./dialog/project/EditProjectDialog";
import DeleteProjectDialog from "./dialog/project/DeleteProjectDialog";
import DeleteClientDialog from "./dialog/client/DeleteClientDialog";

const Project = (props) => {
  const selectedClientId = props.selectedclientId;

  const [projectList, setProjectList] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = React.useState(null);
  const [addModalShow, setAddModalShow] = React.useState(false);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const getClientProjects = async () => {
    if (typeof selectedClientId !== 'undefined' && selectedClientId) {
      try {
        const response = await api.get(`/api/projects?clientId=${selectedClientId}`);
        console.log(response.data);
        setProjectList(response.data.content);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteClientProject = async () => {
    try {
      const response = await api.delete(`/api/projects/${selectedProjectId}`);
      console.log(response.data);
      getClientProjects();
    } catch (err) {
      console.log(err);
    }
  };

  const handleHideAddModal = () => {
    getClientProjects();
    setAddModalShow(false);
  };

  const handleHideEditModal = () => {
    getClientProjects();
    setEditModalShow(false);
  };

  const handleHideDeleteModal = () => {
    getClientProjects();
    setDeleteModalShow(false);
  };

  const handleCreateProject = (projectId) => {
  };

  const handleEditProject = (projectId) => {
    setSelectedProjectId(projectId);
    setEditModalShow(true);
  };

  const handleDeleteProject = (projectId) => {
    setSelectedProjectId(projectId);
    setDeleteModalShow(true);
  };

  useEffect(() => {
    getClientProjects()
  }, [selectedClientId]);

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>Projects</h2>
          </div>
          <div className="card-body border-0">
            <div className="add-button">
              <>
                <Button variant="btn btn-success" onClick={() => setAddModalShow(true)}>
                  Create Project
                </Button>

                <CreateProjectDialog
                    show={addModalShow}
                    onHide={() => handleHideAddModal()}
                    selectedclientId={selectedClientId}
                />

                <EditProjectDialog
                    show={editModalShow}
                    onHide={() => handleHideEditModal()}
                    selectedProjectId={selectedProjectId}
                />

                <DeleteProjectDialog
                    show={deleteModalShow}
                    onHide={() => handleHideDeleteModal()}
                    deleteProject={() => deleteClientProject()}
                />
              </>
            </div>
          </div>
        </div>

        <table className="table table-bordered">
          <thead className="bg-black text-white">
          <tr>
            <td>Project Name</td>
            <td>Project Code</td>
            <td>Address</td>
            <td>Created Date</td>
            <td>ACTION</td>
          </tr>
          </thead>
          <tbody>
          {
            projectList &&
            projectList.map((proejct) => (
                <tr key={proejct.id}>
                  <td>{proejct.projectName}</td>
                  <td>{proejct.projectCode}</td>
                  <td>{proejct.projectAddress}</td>
                  <td>{proejct.createdDate}</td>
                  <td>
                    <button type="button" onClick={() => {handleEditProject(proejct.id)}}
                            className="btn btn-success btn-sm">View & Edit
                    </button>
                    &nbsp;
                    <button type="button" onClick={() => {handleDeleteProject(proejct.id)}}
                            className="btn btn-danger btn-sm">Delete
                    </button>
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </div>
  )
}

export default Project
