import React, {useEffect, useState} from 'react'
import api from "../../../../api/axiosConfig";

const ViewWorkOrderSchedule = (props) => {
  const selectedWorkOrderId = props.selectedWorkOrderId;

  const initialClientState = {
    id: "",
    clientId: "",
    clientName: "",
    clientProjectId: "",
    clientReference: "",
    createdDate: "",
    projectAddress: "",
    projectCode: "",
    projectName: "",
    status: ""
  };

  const [workOrder, setWorkOrder] = useState(initialClientState);
  const [workOrderItemList, setWorkOrderItemList] = useState([]);

  const getWorkOrder = async () => {
    try {
      const response = await api.get(`/api/work-orders/${selectedWorkOrderId}`);
      console.log(response.data);
      setWorkOrder(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getClientWorkOrderItems = async () => {
    try {
      const response = await api.get(`/api/work-order-schedule-sub/work-order?clientWorkOrderId=${selectedWorkOrderId}`);
      console.log(response.data);
      setWorkOrderItemList(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    props.onHide();
  };

  useEffect(() => {
    getWorkOrder();
    getClientWorkOrderItems();
    console.log("Selected id: " + selectedWorkOrderId);
  }, []);

  function stringDateFormat(dateStr) {
    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-SG', options);
  }

  function stringTimeFormat(dateStr) {
    var date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-SG', {timeStyle: 'short', timeZone: 'Asia/Singapore', hour12: true}).format(date)
  }

  return (
      <div className="container">
        <div className="row">
          <div className="offset-lg-0 col-lg-12">
            <div className="card border-0">
              <div className="card-header border-0 center-content">
                <h4>View Work Order Schedule</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Client Reference</h6>
                  </div>
                  <div className="col-sm-2">
                    <h6>{workOrder.clientReference}</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Project</h6>
                  </div>
                  <div className="col-sm-2">
                    <h6>{workOrder.projectName}</h6>
                  </div>
                </div>
                <div>
                  <table className="table table-bordered">
                    <thead className="bg-black text-white">
                    <tr>
                      <td>Client</td>
                      <td>Project</td>
                      <td>Purpose</td>
                      <td>Assignee Type</td>
                      <td>Scheduled Date</td>
                      <td>Time slot</td>
                      <td>Assign Status</td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      workOrderItemList &&
                      workOrderItemList.map((workOrderItem) => (
                          <tr key={workOrderItem.id}>
                            <td>{workOrderItem.clientName}</td>
                            <td>{workOrderItem.clientProjectName}</td>
                            <td>{workOrderItem.projectSection}</td>
                            <td>{workOrderItem.assigneeType}</td>
                            <td>{stringDateFormat(workOrderItem.scheduleDate)}</td>
                            <td>{stringTimeFormat(workOrderItem.startTime) + " - " + stringTimeFormat(workOrderItem.endTime)}</td>
                            <td>{workOrderItem.status}</td>
                          </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer border-0 center-content">
                <button type="button" onClick={handleCancel}
                        className="btn btn-success btn-sm">Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default ViewWorkOrderSchedule
