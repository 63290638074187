import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap';
import {useParams} from "react-router";
import api from "../../api/axiosConfig";
import Figure from "react-bootstrap/Figure";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AssignedWorkOrder from "./AssignedWorkOrder";
import SubmitTimeSheet from "./SubmitTimeSheet";

const ViewAssignee = () => {
  let params = useParams();
  const selectedAssigneeUserId = params.selectedAssigneeId;

  const initialAssigneeState = {
    id: "",
    name: "",
    assigneeType: "",
    mobileNo: "",
    emailAddress: ""
  };

  const [assigneeList, setAssigneeList] = useState([]);
  const [assignee, setAssignee] = useState(initialAssigneeState);
  const [activeTabKey, setActiveTabKey] = useState("1");

  const getAssignee = async () => {
    try {
      const response = await api.get(`/api/assignees?userId=${selectedAssigneeUserId}`);
      console.log(response.data.content);
      setAssigneeList(response.data.content);

      console.log("Assignee list size: " + response.data.content.length);

      if (response.data.content.length > 0) {
        setAssignee(response.data.content[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = event => {
    event.preventDefault();
    const {name, value} = event.target;
    setAssignee({...assignee, [name]: value});
    console.log("Name: " + name + " Value: " + value)
  };

  const handleSelect = (key) => {
    setActiveTabKey(key);
  };

  useEffect(() => {
    getAssignee();
    console.log("Selected id: " + selectedAssigneeUserId);
  }, []);

  return (
      <Container>
        <Row className="text-center">
          <Col><h3>{assignee.name}</h3></Col>
        </Row>
        <Row className="mt-2">
          <Col>
            {
              <>
                <Row className="flex-column h-25 g-0">
                  <Col>
                    {/*<hr/>*/}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <h5>Mobile</h5>
                  </Col>
                  <Col>
                    <h5>{assignee.mobileNo}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <h5>Email</h5>
                  </Col>
                  <Col>
                    <h5>{assignee.emailAddress}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <h5>Assignee Type</h5>
                  </Col>
                  <Col>
                    <select className="form-control" id="id" value={assignee.id} onChange={handleInputChange} name="id">
                      {
                        assigneeList &&
                        assigneeList.map((assignee) => (
                            <option key={assignee.id} value={assignee.id}>{assignee.assigneeType}</option>
                        ))
                      }
                    </select>
                  </Col>
                </Row>
              </>
            }
          </Col>
          <Col xs={5} className="text-end">
            {/*<img src={movie?.poster} alt=""/>*/}
            <Figure>
              <Figure.Image
                  width={192}
                  height={192}
                  alt="171x180"
                  src="/assignee.png"
              />
            </Figure>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
                defaultActiveKey="1"
                activeKey={activeTabKey}
                id="assignee_activity"
                className="mb-3"
                onSelect={handleSelect}>
              <Tab eventKey="1" title="Assigned Work Orders">
                {(activeTabKey === "1") &&
                <AssignedWorkOrder
                    selectedAssigneeId={assignee.id}
                />
                }
              </Tab>
              <Tab eventKey="2" title="Submit Time Sheet">
                {(activeTabKey === "2") &&
                <SubmitTimeSheet
                    selectedAssigneeId={assignee.id}
                />
                }
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
  )
}

export default ViewAssignee
