import React, {useEffect, useRef, useState} from 'react'

const CreateProjectSection = (props) => {
  const initialized = useRef(false);

  const addTableRows = () => {
    console.log("Added section row ... " + props.rowsData.length );
    const rowsInput = {
      id: '',
      clientProjectId: '',
      sectionName: '',
      remarks: ''
    };
    props.setRowsData(oldArray => [...oldArray, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...props.rowsData];
    rows.splice(index, 1);
    props.setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    evnt.preventDefault();
    const {name, value} = evnt.target;
    const rowsInput = [...props.rowsData];
    rowsInput[index][name] = value;
    props.setRowsData(rowsInput);
  };

  useEffect(() => {
    if (!initialized.current) {
      addTableRows();
      initialized.current = true;
    }
  }, [props.rowsData]);

  return (
      <div className="container">
        <div className="row">
          <div>
            <table className="table">
              <thead>
              <tr>
                <th>Work location/Area</th>
                <th>Remarks</th>
                <th></th>
                <th>
                  {(props.rowsData.length === 0) &&
                  <button type="button" onClick={addTableRows}
                          className="btn btn-outline-success btn-sm">+
                  </button>
                  }
                </th>
              </tr>
              </thead>
              <tbody>
              {
                props.rowsData &&
                props.rowsData.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <input className="form-control" id="sectionName"  value={data.sectionName} onChange={(evnt) => (handleChange(index, evnt))}  name="sectionName"/>
                      </td>
                      <td>
                        <input className="form-control" id="remarks"  value={data.remarks} onChange={(evnt) => (handleChange(index, evnt))}  name="remarks"/>
                      </td>
                      <td>
                        <button type="button" onClick={() => (deleteTableRows(index))}
                                className="btn btn btn-outline-danger btn-sm">x
                        </button>
                      </td>
                      <td>
                        {(props.rowsData.length === (index + 1)) &&
                        <button type="button" onClick={addTableRows}
                                className="btn btn-outline-success btn-sm" autoFocus>+
                        </button>
                        }
                      </td>
                    </tr>
                ))
              }
              </tbody>
            </table>
          </div>
          <div className="col-sm-4">
          </div>
        </div>
      </div>
  )
};

export default CreateProjectSection
