import React, {useEffect, useState} from 'react'
import api from "../../../../api/axiosConfig";
import {toast} from "react-toastify";
import CreateWorkOrderItem from "./CreateWorkOrderItem";

const CreateWorkOrder = (props) => {
  const selectedClientId = props.selectedclientid;

  const initialClientState = {
    id: "",
    clientId: selectedClientId,
    clientReference: "",
    clientProjectId: "",
    clientWorkOrderItemRequestList: []
  };

  const [clientWorkOrder, setClientWorkOrder] = useState(initialClientState);
  const [projectList, setProjectList] = useState([]);
  const [projectSectionList, setProjectSectionList] = useState([]);
  const [assigneeTypeList, setAssigneeTypeList] = useState([]);
  const [periodTypeList, setPeriodTypeList] = useState([]);
  const [rowsData, setRowsData] = useState([]);

  const getClientProjects = async () => {
    try {
      const response = await api.get(`/api/projects?clientId=${selectedClientId}`);
      console.log(response.data);
      setProjectList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const getClientProjectSections = async (clientProjectSectionId) => {
    try {
      const response = await api.get(`/api/project-sections?clientProjectId=${clientProjectSectionId}`);
      console.log(response.data);
      setProjectSectionList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const getAssigneeTypes = async () => {
    try {
      const response = await api.get("/api/assignee-types");
      console.log(response.data);
      setAssigneeTypeList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const getPeriodTypes = async () => {
    try {
      const response = await api.get("/api/work-order-period-type");
      console.log(response.data);
      setPeriodTypeList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = event => {
    const {name, value} = event.target;
    setClientWorkOrder({...clientWorkOrder, [name]: value});
    if (name === 'clientProjectId') {
      getClientProjectSections(value);
    }
  };

  const validateInputData = (clientWorkOrder) => {
    if (clientWorkOrder.clientReference === null || clientWorkOrder.clientReference.trim() === "") {
      toast.error("Client reference cannot be empty");
      return false;
    }
    if (clientWorkOrder.clientProjectId === null || clientWorkOrder.clientProjectId.trim() === "") {
      toast.error("Project should be selected");
      return false;
    }

    if(rowsData.length < 1){
      toast.error("There should be at least one work order item");
      return false;
    }

    for (let i = 0; i < rowsData.length; i++) {
      if(rowsData[i].clientProjectSectionId === null || rowsData[i].clientProjectSectionId === ""){
        toast.error("Project section should be selected");
        return false;
      }
      if(rowsData[i].assigneeTypeId === null || rowsData[i].assigneeTypeId === ""){
        toast.error("Assignee type should be selected");
        return false;
      }
      if(rowsData[i].requestStartDate === null || rowsData[i].requestStartDate === ""){
        toast.error("Schedule date or period should be selected");
        return false;
      }
      if(rowsData[i].startTime === null || rowsData[i].startTime === ""){
        toast.error("Start time should be selected");
        return false;
      }
      if(rowsData[i].endTime === null || rowsData[i].endTime === ""){
        toast.error("End time should be selected");
        return false;
      }
    }

    return true;
  };

  const saveClientWorkOrder = async (clientWorkOrder, submitStatus) => {
    var data = {
      id: "",
      clientId: clientWorkOrder.clientId,
      clientProjectId: clientWorkOrder.clientProjectId,
      clientReference: clientWorkOrder.clientReference
    };

    console.log("input data" + clientWorkOrder.clientProjectId);
    console.log("data" + data.clientProjectId);

    try {
      const response = await api.post("/api/work-orders",
          {
            id: data.id,
            clientId: data.clientId,
            clientProjectId: data.clientProjectId,
            clientReference: data.clientReference,
            clientWorkOrderItemRequestList: rowsData,
            submitStatus: submitStatus
          }); // TODO try using body: JSON.Stringfy(data);

      toast.success("Work order saved successfully");
      console.log("<<<<<<<<<" + response.data);
      props.onHide();
    } catch (err) {
      toast.error(err.response.data.errorDetails[0].message);
      console.log(err);
    }
  };

  const handleAddClient = (e) => {
    e.preventDefault();
    if (validateInputData(clientWorkOrder)) {
      saveClientWorkOrder(clientWorkOrder, 'SUBMIT');
    }
  };

  const handleDraftClient = (e) => {
    e.preventDefault();
    if (validateInputData(clientWorkOrder)) {
      saveClientWorkOrder(clientWorkOrder, 'DRAFT');
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    props.onHide();
  };

  useEffect(() => {
    getClientProjects();
    getAssigneeTypes();
    getPeriodTypes();
  }, []);
  return (
      <div>
        <form className="container">
          <div className="row">
            <div className="offset-lg-0 col-lg-12">
              <div className="card border-0">
                <div className="card-header border-0 center-content">
                  <h2>Create Work Order</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Client Reference</label>
                    <input className="form-control" id="clientReference" value={clientWorkOrder.clientReference} onChange={handleInputChange}
                           name="clientReference"/>
                    <label>Project</label>
                    <select className="form-control" id="clientProjectId" value={clientWorkOrder.clientProjectId} onChange={handleInputChange}
                            name="clientProjectId">
                      <option value=""></option>
                      {
                        projectList.map((project) => (
                            <option key={project.id} value={project.id}>{project.projectName}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div>
                    <CreateWorkOrderItem
                        onHide={props.onHide}
                        selectedClientId={props.selectedClientId}
                        clientWorkOrder={clientWorkOrder}
                        rowsData={rowsData}
                        setRowsData={(rows) => {
                          setRowsData(rows)
                        }}
                        projectSectionList={projectSectionList}
                        assigneeTypeList={assigneeTypeList}
                        periodTypeList={periodTypeList}
                    />
                  </div>
                </div>
                <div className="card-footer border-0 center-content">
                  <button type="button" onClick={handleAddClient}
                          className="btn btn-success btn-sm">Submit
                  </button>
                  &nbsp;
                  &nbsp;
                  <button type="button" onClick={handleDraftClient}
                          className="btn btn-success btn-sm">Save Draft
                  </button>
                  &nbsp;
                  &nbsp;
                  <button type="button" onClick={handleCancel}
                          className="btn btn-success btn-sm">Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default CreateWorkOrder
