import React, {useEffect, useState} from 'react'
import api from "../../../../api/axiosConfig";

const ClientUser = (props) => {
  const selectedClientId = props.selectedclientId;

  const [userList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [addModalShow, setAddModalShow] = React.useState(false);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);

  const getUsers = async () => {
    if (typeof selectedClientId !== 'undefined' && selectedClientId) {
      try {
        const response = await api.get(`api/users/client/${selectedClientId}`);
        console.log(response.data);
        setUserList(response.data.content);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleHideAddModal = () => {
    getUsers();
    setAddModalShow(false);
  };

  const handleHideEditModal = () => {
    getUsers();
    setEditModalShow(false);
  };

  const handleHideDeleteModal = () => {
    getUsers();
    setDeleteModalShow(false);
  };

  const handleCreateProject = (projectId) => {
  };

  const handleEditProject = (projectId) => {
    setSelectedUserId(projectId);
    setEditModalShow(true);
  };

  const handleDeleteProject = (projectId) => {
    setSelectedUserId(projectId);
    setDeleteModalShow(true);
  };

  useEffect(() => {
    getUsers()
  }, [selectedClientId]);

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>Users</h2>
          </div>
          <div className="card-body border-0">
            <div className="add-button">
              <>
                {/*<Button variant="btn btn-success" onClick={() => setAddModalShow(true)}>*/}
                {/*  Create Project*/}
                {/*</Button>*/}

                {/*<CreateProjectDialog*/}
                {/*    show={addModalShow}*/}
                {/*    onHide={() => handleHideAddModal()}*/}
                {/*    selectedclientId={selectedClientId}*/}
                {/*/>*/}

                {/*<EditProjectDialog*/}
                {/*    show={editModalShow}*/}
                {/*    onHide={() => handleHideEditModal()}*/}
                {/*    selectedProjectId={selectedProjectId}*/}
                {/*/>*/}

                {/*<DeleteProjectDialog*/}
                {/*    show={deleteModalShow}*/}
                {/*    onHide={() => handleHideDeleteModal()}*/}
                {/*    deleteProject={() => deleteClientProject()}*/}
                {/*/>*/}
              </>
            </div>
          </div>
        </div>

        <table className="table table-bordered">
          <thead className="bg-black text-white">
          <tr>
            <td>Full Name</td>
            <td>Eamil</td>
            <td>Mobile No</td>
            <td>Created Date</td>
            <td>ACTION</td>
          </tr>
          </thead>
          <tbody>
          {
            userList &&
            userList.map((proejct) => (
                <tr key={proejct.id}>
                  <td>{proejct.fullName}</td>
                  <td>{proejct.emailAddress}</td>
                  <td>{proejct.mobileNo}</td>
                  <td>{proejct.createdDate}</td>
                  <td>
                    <button type="button" onClick={() => {
                      handleEditProject(proejct.id)
                    }}
                            className="btn btn-success btn-sm">View & Edit
                    </button>
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </div>
  )
};

export default ClientUser
