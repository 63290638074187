import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AssignWorkOrder from "./AssignWorkOrder";

const AssignWorkOrderDialog = (props) => {
  const selectedAssigneeId = props.selectedAssigneeId;

  return (
      <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          backdrop="static"
          scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Get Work Order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AssignWorkOrder
              onHide={props.onHide}
              selectedAssigneeId={selectedAssigneeId}
          />
        </Modal.Body>
      </Modal>
  )
}

export default AssignWorkOrderDialog
