import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import CreateWorkOrderQuote from "./CreateWorkOrderQuote";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ViewWorkOrderQuotePDF from "./ViewWorkOrderQuotePDF";
import CreateWorkOrderQuoteDetail from "./CreateWorkOrderQuoteDetail";

const CreateViewWorkOrderQuoteDialog = (props) => {

  const [defaultTabKey, setDefaultTabKey] = useState("quote_work_order");

  const onTabSelect = key => {
    if (key === 'quote_work_order') {
      setDefaultTabKey("quote_work_order");
    }
    if (key === 'ot_shift_rate') {
      setDefaultTabKey("ot_shift_rate");
    }
    if (key === 'quote_work_detail') {
      setDefaultTabKey("quote_work_detail");
    }
    console.log("Tab key: " + key);
  };

  const handleOnHide = () => {
    setDefaultTabKey("quote_work_order");
    props.onHide();
  };

  const onViewQuotePdf = () => {
    setDefaultTabKey("view_work_order_quote");
    console.log("view_work_order_quote selected >>>>> ");
  };

  useEffect(() => {
    setDefaultTabKey("quote_work_order");
  }, [props.selectedWorkOrderId]);

  return (
      <Modal
          {...props}
          onHide={handleOnHide}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          backdrop="static"
          scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View Work Order 1
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <>
              <Tabs
                  onSelect={onTabSelect}
                  defaultActiveKey="quote_work_order"
                  activeKey={defaultTabKey}
                  id="client_activity"
                  className="mb-3">
                <Tab eventKey="quote_work_order" title="Quote Work Order">
                  <>
                    <CreateWorkOrderQuote
                        onHide={props.onHide}
                        selectedWorkOrderId={props.selectedWorkOrderId}
                        onViewQuotePdf={onViewQuotePdf}
                    />
                  </>
                </Tab>
                <Tab eventKey="ot_shift_rate" title="OT Shift Rates">
                  <>
                    <CreateWorkOrderQuoteDetail
                        onHide={props.onHide}
                        selectedWorkOrderId={props.selectedWorkOrderId}
                        onViewQuotePdf={onViewQuotePdf}
                    />
                  </>
                </Tab>
                <Tab eventKey="quote_work_detail" title="Work order details">
                  <>
                    <CreateWorkOrderQuoteDetail
                        onHide={props.onHide}
                        selectedWorkOrderId={props.selectedWorkOrderId}
                        onViewQuotePdf={onViewQuotePdf}
                    />
                  </>
                </Tab>
                <Tab eventKey="view_work_order_quote" title="View Work Order Quote">
                  {(defaultTabKey === "view_work_order_quote") &&
                  <>
                    <ViewWorkOrderQuotePDF
                        selectedWorkOrderId={props.selectedWorkOrderId}
                    />
                  </>
                  }
                </Tab>
              </Tabs>
            </>
          }
        </Modal.Body>
        {/*<Modal.Footer>*/}
        {/*  <button variant="secondary" onClick={props.onHide}>*/}
        {/*    Close*/}
        {/*  </button>*/}
        {/*  <button variant="primary">Understood</button>*/}
        {/*</Modal.Footer>*/}
      </Modal>
  )
};

export default CreateViewWorkOrderQuoteDialog
