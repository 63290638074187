export const SetupInterceptors = (http) => {
  const baseURL = 'http://localhost:8080';

  http.interceptors.request.use(
      config => {
        console.log(">>> REQUEST URL >>>  " + config.url);
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
        config.headers['content-type'] = 'application/json';
        return config
      },
      error => {
        return Promise.reject(error)
      }
  );

  // http.interceptors.response.use(function (response) {
  //   return response
  // }, function (error) {
  //   console.log(">>>>>>>>>>>>>>>>>>>>>. ERROR FOUND ")
  //   const status = error?.response?.status || 0;
  //   const resBaseURL = error?.response?.config?.baseUrl;
  //   if (resBaseURL === baseURL && status === 401) {
  //     if (localStorage.getItem('token')) {
  //       localStorage.clear();
  //       window.location.assign('/');
  //       return Promise.reject(error)
  //     } else {
  //       return Promise.reject(error)
  //     }
  //   }
  //   return Promise.reject(error)
  // })

  http.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;

        console.log(">>>>>>>>>>>>>>>>>>>>>. ERROR FOUND status " + err.response.status)
        console.log(">>>>>>>>>>>>>>>>>>>>>. ERROR FOUND url " + originalConfig.url)

        if (originalConfig.url !== "/pub/api/sign-in" && err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
              const rs = await http.post("/pub/api/refresh-token", {
                refreshToken: localStorage.getItem('refreshToken'),
              });

              const {accessToken} = rs.data;
              localStorage.setItem('accessToken', accessToken);
              return http(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          } else if (err.response.status === 403 ) {
            localStorage.clear();
            window.location.assign('/');
          }
        }

        return Promise.reject(err);
      }
  );
};

export default SetupInterceptors
