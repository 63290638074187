import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHelmetSafety, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {NavLink, useLocation, useNavigate,} from "react-router-dom";
import {useEffect, useState} from "react";

const Header = () => {
  const [appState, seAappState] = useState("login");
  const [userId, setUserId] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    seAappState("");
    setUserId("");

    console.log("Loading App header ...");
    let userName = localStorage.getItem('displayName') != null ? localStorage.getItem('displayName').toString() : '';
    if (userName === '') {
      console.log("No user logged in ...");
      console.log("Current location: " + location.pathname);
      console.log("Current location: " + JSON.stringify(location, null, 2));
      if (location.pathname === '/register-assignee') {
        navigate('/register-assignee');
      } else if (location.pathname === '/register-client') {
        navigate('/register-client');
      } else if (location.pathname === '/forgot-password') {
        navigate('/forgot-password');
      } else if (location.pathname.startsWith('/reset-password')) {
        navigate(location.pathname);
      } else if (location.pathname.startsWith('/verify-ext-email')) {
        navigate(location.pathname);
      } else {
        navigate('/login');
      }
      localStorage.setItem('appState', 'login');
    } else {
      console.log("Uer logged in ...");
      let appState = localStorage.getItem('appState') != null ? localStorage.getItem('appState').toString() : 'login';
      seAappState(appState);
      let loggedInUserId = localStorage.getItem('userId') != null ? localStorage.getItem('userId').toString() : '';
      setUserId(loggedInUserId);

      if ('ROLE_ADMIN' === appState) {
        console.log("Admin uer logged in ...");
      } else if ('ROLE_CLIENT_PRIMARY' === appState || 'ROLE_CLIENT' === appState) {
        console.log("Client uer logged in ...");
        navigate(`/client/view/${userId}`);
      } else if ('ROLE_ASSIGNEE' === appState) {
        console.log("Assignee uer logged in ...");
        navigate(`/assignee/view/${userId}`);
      } else {
        console.log("No App state  ...");
        localStorage.clear();
        navigate('/login');
      }
    }
  }, [navigate]);

  return (
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="https://mdssconsultants.com" style={{"color": 'gold'}}>
            <FontAwesomeIcon icon={faHelmetSafety}/>MDSS
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll"/>
          {appState === "ROLE_ADMIN" &&
          <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{maxHeight: '100px'}}
                navbarScroll
            >
              <NavLink className="nav-link" to="/">Home</NavLink>
              <NavLink className="nav-link" to="/work-order">Work Order</NavLink>
              <NavLink className="nav-link" to="/time-sheet">Invoice</NavLink>
              <NavLink className="nav-link" to="/client">Client</NavLink>
              <NavLink className="nav-link" to="/assignee">Assignee</NavLink>
              <NavLink className="nav-link" to="/settings">Settings</NavLink>
            </Nav>
            <Nav>
              <NavLink className="nav-link" to="/logout">
                <FontAwesomeIcon icon={faSignOutAlt}/> Logout
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          }
          {(appState === "ROLE_CLIENT_PRIMARY" || appState === "ROLE_CLIENT") &&
          <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{maxHeight: '100px'}}
                navbarScroll
            >
              {/*<NavLink className="nav-link" to="/">Home</NavLink>*/}
            </Nav>
            <Nav>
              <NavLink className="nav-link" to="/logout">
                <FontAwesomeIcon icon={faSignOutAlt}/> Logout
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          }
          {appState === "ROLE_ASSIGNEE" &&
          <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{maxHeight: '100px'}}
                navbarScroll
            >
              {/*<NavLink className="nav-link" to="/">Home</NavLink>*/}
            </Nav>
            <Nav>
              <NavLink className="nav-link" to="/logout">
                <FontAwesomeIcon icon={faSignOutAlt}/> Logout
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          }
          {(appState === "login" || appState === "register") &&
          <Navbar.Collapse id="navbarScroll">
            <Nav
                className="me-auto my-2 my-lg-0"
                style={{maxHeight: '100px'}}
                navbarScroll
            >
            </Nav>
          </Navbar.Collapse>
          }
        </Container>
      </Navbar>
  )
}
export default Header
