import React, {useEffect, useState} from 'react'
import api from "../../../../api/axiosConfig";
import {toast} from "react-toastify";
import EditProejctSection from "./EditProejctSection";

const EditProject = (props) => {
  const selectedProjectId = props.selectedProjectId;

  const initialClientState = {
    id: "",
    clientId: "",
    projectName: "",
    projectCode: "",
    projectAddress: ""
  };

  const [project, setProject] = useState(initialClientState);
  const [rowsData, setRowsData] = useState([]);

  const handleInputChange = event => {
    const {name, value} = event.target;
    setProject({...project, [name]: value});
  };

  const getProject = async () => {
    try {
      const response = await api.get(`/api/projects/${selectedProjectId}`);
      console.log(response.data);
      setProject(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjectSections = async () => {
    try {
      const response = await api.get(`/api/project-sections?clientProjectId=${selectedProjectId}`);
      console.log(response.data);
      setRowsData(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const validateInputData = (project) => {
    if (project.projectName === null || project.projectName.trim() === "") {
      toast.error("Project Name cannot be empty");
      return false;
    }
    if (project.projectName === null || project.projectName.trim() === "") {
      toast.error("Project Code cannot be empty");
      return false;
    }
    if (project.projectAddress === null || project.projectAddress.trim() === "") {
      toast.error("Project Address cannot be empty");
      return false;
    }

    if(rowsData.length < 1){
      toast.error("There should be at least one proeject section");
      return false;
    }

    for (let i = 0; i < rowsData.length; i++) {
      if(rowsData[i].sectionName === null || rowsData[i].sectionName === ""){
        toast.error("Project section name cannot be empty");
        return false;
      }
    }

    return true;
  };

  const updateProject = async (project) => {
    var data = {
      id: project.id,
      clientId: project.clientId,
      projectName: project.projectName,
      projectCode: project.projectCode,
      projectAddress: project.projectAddress
    };

    console.log("input data" + project.projectName);
    console.log("data" + data.projectName);

    try {
      const response = await api.put(`/api/projects/${selectedProjectId}`,
          {
            id: data.id,
            projectName: data.projectName,
            projectCode: data.projectCode,
            projectAddress: data.projectAddress,
            clientProjectSectionRequestList: rowsData
          }); // TODO try using body: JSON.Stringfy(data);

      toast.success("Project updated successfully");
      console.log(response.data);
      props.onHide();
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateProject = (e) => {
    e.preventDefault();
    if (validateInputData(project)) {
      updateProject(project);
      props.onHide();
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    props.onHide();
  };

  useEffect(() => {
    getProject();
    getProjectSections();
    console.log("Selected project id: " + selectedProjectId);
  }, []);

  return (
      <div>
        <form className="container">
          <div className="row">
            <div className="offset-lg-0 col-lg-12">
              <div className="card border-0">
                <div className="card-header border-0 center-content">
                  <h2>Update Project</h2>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Project Name</label>
                    <input className="form-control" id="projectName" value={project.projectName} onChange={handleInputChange} name="projectName"/>
                    <label>Project code</label>
                    <input className="form-control" id="projectCode" value={project.projectCode} onChange={handleInputChange} name="projectCode"/>
                    <label>Project Addess</label>
                    <input className="form-control" id="projectAddress" value={project.projectAddress} onChange={handleInputChange}
                           name="projectAddress"/>
                  </div>
                  <div>
                    <EditProejctSection
                        onHide={props.onHide}
                        rowsData={rowsData}
                        setRowsData={(rows) => {
                          setRowsData(rows)
                        }}
                    />
                  </div>
                </div>
                <div className="card-footer border-0 center-content">
                  <button type="button" onClick={handleUpdateProject}
                          className="btn btn-success btn-sm">Update
                  </button>
                  &nbsp;
                  <button type="button" onClick={handleCancel}
                          className="btn btn-success btn-sm">Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
}

export default EditProject
