import React, {useEffect, useState} from 'react'
import api from "../../api/axiosConfig";
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import {useNavigate} from "react-router";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";

const AllActiveProjects = (props) => {
	const navigate = useNavigate();

	var date = new Date();
	const [isClientProjectsLoading, setIsClientProjectsLoading] = React.useState(false);
	const [selectedStartDate, setSelectedStartDate] = React.useState(new Date(date.getFullYear(), date.getMonth(), 1));
	const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(date.getFullYear(), date.getMonth() + 1, 1));

	const [clientProjectsList, setClientProjectsList] = useState([]);

	const getClientWorkOrders = async (startDate, endDate) => {
		setIsClientProjectsLoading(true);
		try {
			const response = await api.get(`/api/projects?startDate=${startDate}&endDate=${endDate}&loadProjectWorkOrders=true`);
			console.log(response.data);
			setClientProjectsList(response.data.content);
		} catch (err) {
			console.log(err);
		} finally {
			setIsClientProjectsLoading(false);
		}
	};

	const createInvoice = async (projectId) => {
		try {
			const response = await api.post("/api/invoice",
				{
					clientProjectId: projectId,
					startDate: selectedStartDate.toISOString(),
					endDate: selectedEndDate.toISOString()
				}); // TODO try using body: JSON.Stringfy(data);

			toast.success("Invoice created successfully");
			console.log(response.data);
		} catch (err) {
			toast.error(err.response.data.errorDetails[0].message);
			console.log(err);
		}
	};

	const handleStartDate = (update) => {
		var selectedDate = new Date(update);
		var startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
		var endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1)
		setSelectedStartDate(startDate);
		setSelectedEndDate(endDate);

		console.log("SelectedDate: " + selectedDate.toLocaleDateString());
		console.log("Start Date: " + startDate.toLocaleDateString());
		console.log("End Date: " + endDate.toLocaleDateString());

		getClientWorkOrders(startDate.toISOString(), endDate.toISOString())
	};

	const handleEditWorkOrderSchedule = (selectedProjectId, selectedWorkOrderId) => {
		console.log("Selected work order id: " + selectedWorkOrderId);
		console.log("Selected project id: " + selectedProjectId);
		localStorage.setItem('invoiceStartDate', selectedStartDate);
		localStorage.setItem('invoiceEndDate', selectedEndDate);

		navigate(`/time-sheet/edit/${selectedProjectId}/${selectedWorkOrderId}`);
	};

	useEffect(() => {
		let invoiceStartDate = localStorage.getItem('invoiceStartDate') !== null ? new Date(localStorage.getItem('invoiceStartDate').toString()) : null;
		let invoiceEndDate = localStorage.getItem('invoiceEndDate') !== null ? new Date(localStorage.getItem('invoiceEndDate').toString()) : null;

		if(null === invoiceStartDate || null === invoiceEndDate) {
			getClientWorkOrders(selectedStartDate.toISOString(), selectedEndDate.toISOString())
		}else{
			setSelectedStartDate(invoiceStartDate);
			setSelectedEndDate(invoiceEndDate);
			localStorage.removeItem('invoiceStartDate');
			localStorage.removeItem('invoiceEndDate');
			getClientWorkOrders(invoiceStartDate.toISOString(), invoiceEndDate.toISOString())
		}
	}, []);

	return (
		<div className="container">
			<div className="card border-0">
				<div className="card-header border-0 center-content">
					<h2>Create Invoice</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-2">
					<h6>Invoice Month: </h6>
				</div>
				<div className="col-sm-2">
					<DatePicker
						selected={selectedStartDate}
						onChange={(date) => handleStartDate(date)}
						selectsStart
						startDate={selectedStartDate}
						dateFormat="yyyy - MMM"
						showMonthYearPicker
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-2">
					<h6>&nbsp;</h6>
				</div>
			</div>
			<table className="table table-bordered">
				<thead className="bg-black text-white">
				<tr>
					<td>Client Name</td>
					<td>Project Name</td>
					<td>Project Code</td>
					<td>Project Address</td>
					<td>Created Date</td>
					<td>ACTION</td>
				</tr>
				</thead>
				<tbody>
				{
					isClientProjectsLoading ? (
						<tr>
							<td rowSpan="1" colSpan="6">
								<div className="text-center py-5">
									<Spinner animation="border"/>
								</div>
							</td>
						</tr>
					) : clientProjectsList && clientProjectsList.length > 0 ?
						(clientProjectsList.map((clientProject) => (
							<>
								<tr key={clientProject.id}>
									<td>{clientProject.clientName}</td>
									<td>{clientProject.projectName}</td>
									<td>{clientProject.projectCode}</td>
									<td>{clientProject.projectAddress}</td>
									<td>{clientProject.createdDate}</td>
									<td>
										<button type="button" onClick={() => {
											createInvoice(clientProject.id)
										}}
										        className="btn btn-success btn-sm">Create Invoice
										</button>
									</td>
								</tr>
								<tr key={clientProject.id + '1'}>
									<td colSpan="6">
										<Accordion defaultActiveKey="-1" flush>
											<Accordion.Item eventKey="0">
												<Accordion.Header>Work Order List - Complany : <b>{clientProject.clientName}</b> - Project: <b>{clientProject.projectCode}</b> </Accordion.Header>
												<Accordion.Body>
													<table className="table table-bordered">
														<thead className="bg-black text-white">
														<tr>
															<td>Client Name</td>
															<td>Work Order Reference</td>
															<td>Project Name</td>
															<td>Created Date</td>
															<td>Status</td>
															<td>ACTION</td>
														</tr>
														</thead>
														<tbody>
														{
															clientProject && clientProject.clientWorkOrderResponseList && clientProject.clientWorkOrderResponseList.length > 0 ?
																(clientProject.clientWorkOrderResponseList.map((workOrder) => (
																	<tr key={workOrder.id}>
																		<td>{workOrder.clientName}</td>
																		<td>{workOrder.clientReference}</td>
																		<td>{workOrder.clientProjectId}</td>
																		<td>{workOrder.createdDate}</td>
																		<td>{workOrder.status}</td>
																		<td>
																			{((workOrder.status === 'CLIENT_ACCEPTED') || (workOrder.status === 'PENDING_ASSIGN')) &&
																			<>
																				&nbsp;
																				<button type="button" onClick={() => {
																					handleEditWorkOrderSchedule(workOrder.clientProjectId, workOrder.id)
																				}}
																				        className="btn btn-success btn-sm">View and Update Time Sheet
																				</button>
																			</>
																			}
																		</td>
																	</tr>
																))) : (
																	<tr>
																		<td colSpan="6">
																			<h5 className="text-danger text-center">No work orders found</h5>
																		</td>
																	</tr>
																)
														}
														</tbody>
													</table>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</td>
								</tr>
							</>
						))) : (
							<tr>
								<td colSpan="6">
									<h5 className="text-danger text-center">No active client projects found</h5>
								</td>
							</tr>
						)
				}
				</tbody>
			</table>
		</div>
	)
};

export default AllActiveProjects
