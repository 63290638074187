import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EditClient from "./EditClient";

const EditClientDialog = (props) => {
  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <EditClient
                onHide={props.onHide}
                selectedclientid={props.selectedclientid}
            />
          }
        </Modal.Body>
      </Modal>
  )
};
export default EditClientDialog
