import React, {useEffect, useState} from 'react'
import api from "../../api/axiosConfig";
import DatePicker from "react-datepicker";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router";

const WorkOrderEditTimeSheet = (props) => {
	const navigate = useNavigate();
	const selectedAssigneeId = props.selectedAssigneeId;

	let params = useParams();
	const selectedWorkOrderId = params.selectedWorkOrderId;
	const selectedProjectId = params.selectedProjectId;

	const [timeSheetEntryList, setTimeSheetEntryList] = useState([]);

	const getAssignedWorkOrderSchedule = async () => {
		try {
			const response = await api.get(`/api/work-order-time-sheet/invoice?workOrderId=${selectedWorkOrderId}`);
			console.log(response.data);
			setTimeSheetEntryList(response.data.content);
		} catch (err) {
			console.log(err);
		}
	};

	const updateTimeSheetEntry = async (index) => {
		console.log("Index: " + index);

		try {
			const response = await api.put(`/api/work-order-time-sheet/${timeSheetEntryList[index]['timeSheetId']}`,
				{
					id: timeSheetEntryList[index]['timeSheetId'],
					startTime: timeSheetEntryList[index]['startTime'],
					endTime: timeSheetEntryList[index]['endTime']
				}); // TODO try using body: JSON.Stringfy(data);

			console.log("Index: " + index);
			const tmpTimeSheetEntryList = [...timeSheetEntryList];
			tmpTimeSheetEntryList[index]['submitStatus'] = response.data.submitStatus;
			tmpTimeSheetEntryList[index]['disabled'] = response.data.disabled;
			setTimeSheetEntryList(tmpTimeSheetEntryList);

			toast.success("Time sheet entry updated successfully");
			console.log(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	const getTimeSheetEntry = async (index) => {
		console.log("Index: " + index);

		try {
			const response = await api.get(`/api/work-order-time-sheet/${timeSheetEntryList[index]['timeSheetId']}`); // TODO try using body: JSON.Stringfy(data);
			console.log("Index: " + index);
			const tmpTimeSheetEntryList = [...timeSheetEntryList];
			tmpTimeSheetEntryList[index]['submitStatus'] = response.data.submitStatus;
			tmpTimeSheetEntryList[index]['disabled'] = response.data.disabled;
			tmpTimeSheetEntryList[index]['startTime'] = response.data.startTime;
			tmpTimeSheetEntryList[index]['endTime'] = response.data.endTime;
			setTimeSheetEntryList(tmpTimeSheetEntryList);
			console.log(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	const backToAllWorkOrders = () => {
		navigate(`/time-sheet`);
	};

	const handleEditTimeSheet = (index) => {
		const tmpTimeSheetEntryList = [...timeSheetEntryList];
		tmpTimeSheetEntryList[index]['disabled'] = false;
		setTimeSheetEntryList(tmpTimeSheetEntryList);
	};

	const handleCancelEditTimeSheet = (index) => {
		getTimeSheetEntry(index);
	};

	const handleSubmitTimeSheet = (index) => {
		updateTimeSheetEntry(index);
	};

	const handleStartTime = (index, update) => {
		console.log("Index: " + index);
		const tmpTimeSheetEntryList = [...timeSheetEntryList];
		tmpTimeSheetEntryList[index]['startTime'] = update;
		setTimeSheetEntryList(tmpTimeSheetEntryList);

		console.log("Start Time: " + update);
		console.log("Start Time: " + (new Date(update)).toUTCString());
		console.log("Start Time: " + (new Date(update)).toISOString());
	};

	const handleEndTIme = (index, update) => {
		console.log("Index: " + index);
		const tmpTimeSheetEntryList = [...timeSheetEntryList];
		tmpTimeSheetEntryList[index]['endTime'] = update;
		setTimeSheetEntryList(tmpTimeSheetEntryList);

		console.log("End time: " + update);
		console.log("End time: " + (new Date(update)).toISOString());
	};

	function stringDateFormat(dateStr) {
		const options = {month: 'long', day: 'numeric', year: 'numeric'};
		const date = new Date(dateStr);
		return date.toLocaleDateString('en-SG', options);
	}

	function stringTimeFormat(dateStr) {
		var date = new Date(dateStr);
		return new Intl.DateTimeFormat('en-SG', {timeStyle: 'short', timeZone: 'Asia/Singapore', hour12: true}).format(date)
	}

	useEffect(() => {
		getAssignedWorkOrderSchedule();
		console.log("Selected assignee id: " + selectedAssigneeId);
		console.log("Selected work order id: " + selectedWorkOrderId);
		console.log("Selected project id: " + selectedProjectId);
	}, [selectedAssigneeId, selectedWorkOrderId, selectedProjectId]);

	return (
		<div className="container">
			<div className="card border-0">
				<div className="card-header border-0 center-content">
					<h2>Submit Time Sheet For Work Order</h2>
				</div>
				<div className="card-body border-0">
					<div className="add-button">
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-2">
					<button type="button" onClick={() => {
						backToAllWorkOrders()
					}}
					        className="btn btn-success">
						Back
					</button>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-2">
					<h6>&nbsp;</h6>
				</div>
			</div>
			<table className="table table-bordered table-hover">
				<thead className="align-middle table-danger">
				<tr>
					<td>Purpose</td>
					<td>Type</td>
					<td>Status</td>
					<td>Assignee Name</td>
					<td>Date</td>
					<td>Original Time Slot</td>
					<td>Start Time</td>
					<td>End Time</td>
					<td>ACTION</td>
				</tr>
				</thead>
				<tbody>
				{
					timeSheetEntryList &&
					timeSheetEntryList.map((workOrder, index) => (
						<tr key={workOrder.id} className={workOrder.scheduleType === 'PRIMARY' ? (workOrder.status === 'SPLITTED' ? 'table-primary': 'table-dark') : 'table-warning'}>
							<td>{workOrder.projectSection}</td>
							<td>{workOrder.assigneeType}</td>
							<td>{workOrder.status}</td>
							<td>{workOrder.assigneeName}</td>
							<td style={{width: '150px'}}>{stringDateFormat(workOrder.scheduleDate)}</td>
							<td>{stringTimeFormat(workOrder.originalStartTime) + " - " + stringTimeFormat(workOrder.originalEndTime)}</td>
							<td style={{width: '120px'}}>
								<DatePicker
									className="form-control"
									selected={new Date(workOrder.startTime)}
									onChange={(date) => handleStartTime(index, date)}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={30}
									timeCaption="Time"
									dateFormat="h:mm aa"
									disabled={timeSheetEntryList[index]['disabled'] || workOrder.status === 'SPLITTED'|| workOrder.status === 'NOT_ASSIGNED'}
								/>
							</td>
							<td style={{width: '120px'}}>
								<DatePicker
									className="form-control e-small"
									selected={new Date(workOrder.endTime)}
									onChange={(date) => handleEndTIme(index, date)}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={30}
									timeCaption="Time"
									dateFormat="h:mm aa"
									disabled={timeSheetEntryList[index]['disabled'] || workOrder.status === 'SPLITTED'|| workOrder.status === 'NOT_ASSIGNED'}
								/>
							</td>
							<td>
								{(workOrder.submitStatus === 'SUBMITTED') && timeSheetEntryList[index]['disabled'] &&
								<>
									<button type="button" onClick={() => {
										handleEditTimeSheet(index)
									}}
									        className="btn btn-success btn-sm">Edit
									</button>
								</>
								}
								{(workOrder.submitStatus === 'NOT_SUBMITTED') &&
								<>
									<button type="button" onClick={() => {
										handleSubmitTimeSheet(index)
									}}
									        className="btn btn-warning btn-sm">Submit
									</button>
								</>
								}
								{(workOrder.submitStatus === 'SUBMITTED') && !timeSheetEntryList[index]['disabled'] &&
								<>
									<button type="button" onClick={() => {
										handleCancelEditTimeSheet(index)
									}}
									        className="btn btn-success btn-sm">Revert
									</button>
									&nbsp;
									<button type="button" onClick={() => {
										handleSubmitTimeSheet(index)
									}}
									        className="btn btn-success btn-sm">Submit
									</button>
								</>
								}
							</td>
						</tr>
					))
				}
				</tbody>
			</table>
		</div>
	)
};

export default WorkOrderEditTimeSheet
