import React, {useEffect, useState} from 'react'
import {toast} from "react-toastify";
import api from "../../api/axiosConfig";
import {useNavigate} from "react-router";

const RegisterAssignee = () => {
  const navigate = useNavigate();

  const initialAssigneeState = {
    id: "",
    name: "",
    assigneeTypeId: "",
    mobileNo: "",
    emailAddress: "",
    password: "",
    confirmPassword: ""
  };

  const [assigneeTypeList, setAssigneeTypeList] = useState([]);
  const [assignee, setAssignee] = useState(initialAssigneeState);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setAssignee({ ...assignee, [name]: value });
  };

  const validateInputData = (assignee) => {
    if (assignee.name === null || assignee.name.trim() === "") {
      toast.error("Full Name cannot be empty");
      return false;
    }
    if (assignee.mobileNo === null || assignee.mobileNo.trim() === "") {
      toast.error("Mobile phone number cannot be empty");
      return false;
    }
    if (assignee.emailAddress === null || assignee.emailAddress.trim() === "") {
      toast.error("Email cannot be empty");
      return false;
    }
    if (assignee.password === null || assignee.password.trim() === "") {
      toast.error("Password cannot be empty");
      return false;
    }
    if (assignee.password === null || assignee.password.trim() !== assignee.confirmPassword.trim()) {
      toast.error("Password does not match");
      return false;
    }
    if (assignee.assigneeTypeId === null || assignee.assigneeTypeId.trim() === "") {
      toast.error("Assignee type cannot be empty");
      return false;
    }
    return true;
  };

  const getAssigneeTyps = async () => {
    try {
      const response = await api.get("/api/assignee-types");
      console.log(response.data);
      setAssigneeTypeList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const saveAssignee = async (assignee) => {
    var data = {
      id: "",
      name: assignee.name,
      emailAddress: assignee.emailAddress,
      mobileNo: assignee.mobileNo,
      assigneeTypeId: assignee.assigneeTypeId,
      password: assignee.password,
      confirmPassword: assignee.confirmPassword
    };

    console.log("input data: " + assignee.name);
    console.log("data: " + data.name);

    try {
      const response = await api.post("/pub/register/register-assignee",
          {
            id: data.id,
            name: data.name,
            assigneeTypeId:data.assigneeTypeId,
            emailAddress:data.emailAddress,
            mobileNo:data.mobileNo,
            password:data.password,
            confirmPassword:data.confirmPassword
          }); // TODO try using body: JSON.Stringfy(data);

      toast.success("Assignee saved successfully");
      navigate("/login");
      console.log(response.data);
    } catch (err) {
      toast.error(err.response.data.errorDetails[0].message);
      console.log(err);
    }
  };

  const handleAddAssignee = (e) => {
    e.preventDefault();
    if (validateInputData(assignee)) {
      saveAssignee(assignee);
    }
  };

  useEffect(() => {
    getAssigneeTyps();
  }, []);

  return (
      <div>
        <form className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6">
              <div className="card border-0">
                <div className="card-body">
                  <div className="form-group">
                    <div className="center-content">
                      <h5>Assignee details</h5>
                    </div>
                    <label>Full Name <span className="text-danger">*</span></label>
                    <input className="form-control" id="name"  value={assignee.name} onChange={handleInputChange} name="name"/>
                    <label>Email <span className="text-danger">*</span></label>
                    <input className="form-control" id="emailAddress" value={assignee.emailAddress} onChange={handleInputChange} name="emailAddress"/>
                    <label>Mobile <span className="text-danger">*</span></label>
                    <input className="form-control" id="mobileNo" value={assignee.mobileNo} onChange={handleInputChange} name="mobileNo"/>

                    <label>Password<span className="text-danger">*</span></label>
                    <input className="form-control" id="password" value={assignee.password} type="password" onChange={handleInputChange} name="password"/>
                    <label>Confirm Password<span className="text-danger">*</span></label>
                    <input className="form-control" id="confirmPassword" value={assignee.confirmPassword} type="password" onChange={handleInputChange} name="confirmPassword"/>

                    <label>Assignee type <span className="text-danger">*</span></label>
                    <select className="form-control" id="assigneeTypeId" value={assignee.assigneeTypeId} onChange={handleInputChange} name="assigneeTypeId">
                      <option value=""> </option>
                      {
                        assigneeTypeList &&
                        assigneeTypeList.map((assigneeType) => (
                            <option value={assigneeType.id}>{assigneeType.type}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <div className="center-content">
                  <button type="button" onClick={handleAddAssignee}
                          className="btn btn-success btn-sm">Register
                  </button>
                  &nbsp;
                  <button type="button" onClick={() => navigate("/login")}
                          className="btn btn-success btn-sm">Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};

export default RegisterAssignee
