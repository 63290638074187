import React from 'react'
import Modal from 'react-bootstrap/Modal';
import CreateProject from "./CreateProject";

const CreateProjectDialog = (props) => {
  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateProject
              onHide={props.onHide}
              selectedclientId={props.selectedclientId}
          />
        </Modal.Body>
      </Modal>
  )
};

export default CreateProjectDialog
