import React, {useEffect, useState} from 'react'
import {Document, Page, pdfjs} from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {baseURL} from "../../../api/AppConfig";

const ViewWorkOrderQuotePDF = (props) => {

  const [pdfFilePath, setPdfFilePath] = useState('');

  useEffect(() => {
    let pdfFilePath = baseURL + `/api/work-order-quote/download/${props.selectedWorkOrderId}/${props.selectedWorkOrderId}.pdf`;
    setPdfFilePath(pdfFilePath);
    console.log("Base URL: " + baseURL);
    console.log("Generated pdf file path: " + pdfFilePath);
  }, [props.selectedWorkOrderId]);

  return (
      <div className="container" style={{
        border: 'solid',
        display: 'flex',
        width: '100%',
        borderRadius: '3px',
        backgroundColor: 'gray',
        // padding: '50px',
      }}>
        <object
            data={pdfFilePath}
            //type="application/pdf"
            width="100%"
            style={{height: 'calc(100vh - 250px)'}}
            aria-label="This object displays an PDF file"
        />
      </div>
  );
};

export default ViewWorkOrderQuotePDF
