import React, {useEffect, useState} from 'react'
import Button from "react-bootstrap/Button";
import AssignWorkOrderDialog from "./dialog/workorder/AssignWorkOrderDialog";
import api from "../../api/axiosConfig";
import {toast} from "react-toastify";

const AssignedWorkOrder = (props) => {
  const selectedAssigneeId = props.selectedAssigneeId;

  const [modalShow, setModalShow] = React.useState(false);
  const [workOrderList, setWorkOrderList] = useState([]);

  const getAssignedWorkOrderSchedule = async () => {
    try {
      const response = await api.get(`/api/work-order-schedule-sub/assigned?assigneeId=${selectedAssigneeId}`);
      console.log(response.data);
      setWorkOrderList(response.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  const unAssignWorkOrderSchedule = async (workOrderScheduleSubId) => {
    var data = {
      id: workOrderScheduleSubId,
      assigneeId: selectedAssigneeId
    };

    try {
      const response = await api.put(`/api/work-order-schedule-sub/un-assign/${workOrderScheduleSubId}`,
          {id: data.id, assigneeId: data.assigneeId}); // TODO try using body: JSON.Stringfy(data);
      toast.success("Schedule un assigned successfully");
      getAssignedWorkOrderSchedule();
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateWorkOrder = (workOrderId) => {
    // navigate(`/client/view/${workOrderId}`);
  };

  const handleEditWorkOrder = (workOrderId) => {
    // navigate(`/client/edit/${workOrderId}`);
  };

  const handleUnAssignWorkOrder = (workOrderScheduleSubId) => {
    unAssignWorkOrderSchedule(workOrderScheduleSubId);
  };

  const handleHideGetWorkOrder = () => {
    getAssignedWorkOrderSchedule();
    setModalShow(false)
  };

  useEffect(() => {
    getAssignedWorkOrderSchedule();
    console.log("Selected id: " + selectedAssigneeId);
  }, [selectedAssigneeId]);

  function stringDateFormat(dateStr) {
    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-SG', options);
  }

  function stringTimeFormat(dateStr) {
    var date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-SG', {timeStyle: 'short', timeZone: 'Asia/Singapore', hour12: true}).format(date)
  }

  return (
      <div className="container">
        <div className="card border-0">
          <div className="card-header border-0 center-content">
            <h2>Assigned Work Orders</h2>
          </div>
          <div className="card-body border-0">
            <div className="add-button">
              <>
                <Button variant="btn btn-success" onClick={() => setModalShow(true)}>
                  Get Work Order
                </Button>

                <AssignWorkOrderDialog
                    show={modalShow}
                    onHide={() => handleHideGetWorkOrder()}
                    selectedAssigneeId={selectedAssigneeId}
                />
              </>
            </div>
          </div>
        </div>

        <table className="table table-bordered table-hover">
          <thead className="align-middle table-danger">
          <tr>
            <td>Client</td>
            <td>Project Name</td>
            <td>Purpose</td>
            <td>Type</td>
            <td>Scheduled Date</td>
            <td>Scheduled End Date</td>
            <td>Shift hours</td>
            <td>ACTION</td>
          </tr>
          </thead>
          <tbody>
          {
            workOrderList &&
            workOrderList.map((workOrder) => (
                <tr key={workOrder.id} className="table-dark">
                  <td>{workOrder.clientName}</td>
                  <td>{workOrder.clientProjectName}</td>
                  <td>{workOrder.projectSection}</td>
                  <td>{workOrder.assigneeType}</td>
                  <td>{stringDateFormat(workOrder.scheduleDate)}</td>
                  <td>{stringDateFormat(workOrder.scheduleEndDate)}</td>
                  <td>{stringTimeFormat(workOrder.startTime) + " - " + stringTimeFormat(workOrder.endTime)}</td>
                  <td>
                    <button type="button" onClick={() => {
                      handleCreateWorkOrder(workOrder.id)
                    }}
                            className="btn btn-warning btn-sm">View
                    </button>
                    &nbsp;
                    <button type="button" onClick={() => {
                      handleUnAssignWorkOrder(workOrder.id)
                    }}
                            className="btn btn-danger btn-sm">Un Assign
                    </button>
                  </td>
                </tr>
            ))
          }
          </tbody>
        </table>
      </div>
  )
};

export default AssignedWorkOrder
