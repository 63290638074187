import React, {useEffect, useState} from 'react'
import api from "../../../api/axiosConfig";
import {toast} from "react-toastify";

const CreateWorkOrderQuoteDetail = (props) => {
  const selectedWorkOrderId = props.selectedWorkOrderId;

  const initialWorkOrderState = {
    id: "",
    letterTo: "",
    letterToProject: "",
    letterHeaderLine: "",
    paragraphLine1: "",
    submittedByUser: "",
    submittedByUserPosition: "",
    approvedByCompany: "",
  };

  const [workOrder, setWorkOrder] = useState(initialWorkOrderState);

  const getWorkOrderQuoteDetail = async () => {
    try {
      const response = await api.get(`/api/work-order-quote-detail/${selectedWorkOrderId}`);
      console.log(response.data);
      setWorkOrder(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const updateWorkOrderStatus = async () => {
    try {
      const response = await api.put(`/api/work-order-quote-detail/${workOrder.id}`,
          {
            id: workOrder.id,
            letterTo: workOrder.letterTo,
            letterToProject: workOrder.letterToProject,
            letterHeaderLine: workOrder.letterHeaderLine,
            paragraphLine1: workOrder.paragraphLine1,
            submittedByUser: workOrder.submittedByUser,
            submittedByUserPosition: workOrder.submittedByUserPosition,
            approvedByCompany: workOrder.approvedByCompany,
          }); // TODO try using body: JSON.Stringfy(data);
      toast.success("Work order quote detail updated successfully");
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputOtRateChange = event => {
    const {name, value} = event.target;
    setWorkOrder({...workOrder, [name]: value});
  };

  useEffect(() => {
    getWorkOrderQuoteDetail();
    console.log("Selected id: " + selectedWorkOrderId);
  }, []);

  return (
      <div className="container">
        <div className="row">
          <div className="offset-lg-0 col-lg-12">
            <div className="card border-0">
              <div className="card-header border-0 center-content">
                <h4>View Work Order</h4>
              </div>
              <div className="card-body">

                <div className="row">
                  <div className="col-sm-2">
                    <h6>To </h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="letterTo" value={workOrder.letterTo} onChange={handleInputOtRateChange}
                           name="letterTo"/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Project Name</h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="letterToProject" value={workOrder.letterToProject} onChange={handleInputOtRateChange}
                           name="letterToProject"/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2">
                    <h6>Header</h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="letterHeaderLine" value={workOrder.letterHeaderLine} onChange={handleInputOtRateChange}
                           name="letterHeaderLine"/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Paragraph Line 1</h6>
                  </div>
                  <div className="col-sm-10">
                    <input className="form-control" id="paragraphLine1" value={workOrder.paragraphLine1} onChange={handleInputOtRateChange}
                           name="paragraphLine1"/>
                  </div>
                </div>

                <hr/>

                <div className="row">
                  <div className="col-sm-2">
                    <h6>Submitted by</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="submittedByUser" value={workOrder.submittedByUser} onChange={handleInputOtRateChange}
                           name="submittedByUser"/>
                  </div>
                  <div className="col-sm-2">
                    <h6>Submitted by Position</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="submittedByUserPosition" value={workOrder.submittedByUserPosition} onChange={handleInputOtRateChange}
                           name="submittedByUserPosition"/>
                  </div>
                </div>

                 <div className="row">
                  <div className="col-sm-2">
                    <h6>Accepted by</h6>
                  </div>
                  <div className="col-sm-4">
                    <input className="form-control" id="approvedByCompany" value={workOrder.approvedByCompany} onChange={handleInputOtRateChange}
                           name="approvedByCompany"/>
                  </div>
                </div>

              </div>
              <div className="card-footer border-0 center-content">
                <button type="button" onClick={updateWorkOrderStatus}
                        className="btn btn-success btn-sm">Update Quote Detail
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default CreateWorkOrderQuoteDetail

